// @flow
import { type Actions } from "../actions/rendering";

export type QuickLinksState = {
  areQuickLinksVisible: boolean
};

export const initialState: QuickLinksState = {
  areQuickLinksVisible: true
};

export default function reducer(
  state: QuickLinksState = initialState,
  action: Actions
): QuickLinksState {
  switch (action.type) {
    case "HIDE_QUICK_LINKS":
      return {
        areQuickLinksVisible: false
      };
    case "SHOW_QUICK_LINKS":
      return {
        areQuickLinksVisible: true
      };
    default:
      return state;
  }
}
