// @flow
import React from "react";
import NoVideoIcon from "../../../assets/svg/mtp-novideo.svg";
import style from "./style.css";

type Props = {
  /** Title Label */
  title: string,
  /** Description Label */
  description: string
};

/**
 * Video not Availeble Component
 * @param {Props} props
 */
const NoVideo = (props: Props) => (
  <div className={style.NoVideoContainer}>
    <div className={style.NoVideoTitle}>{props.title}</div>
    <NoVideoIcon className={style.NoVideoIcon} />
    <div className={style.NoVideoDescription}>{props.description}</div>
  </div>
);

export default NoVideo;
