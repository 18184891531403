export default function reducer(
  state = {
    leftBarRaces: [],
    leftBarFeaturedRaces: [],
    leftBarRacesLoading: true,
    openSideMenu: false
  },
  action
) {
  switch (action.type) {
    case "LHN_REQUEST_PENDING": {
      return { ...state, loading: true };
    }
    case "LHN_OPEN_SIDE_MENU": {
      return { ...state, openSideMenu: action.payload };
    }
    case "LHN_REQUEST_SUCCESS_NO_RESULTS": {
      return {
        leftBarRaces: [],
        leftBarFeaturedRaces: [],
        loading: false,
        openSideMenu: state.openSideMenu
      };
    }
    case "LHN_REQUEST_SUCCESS": {
      return {
        leftBarRaces: action.payload.leftBarRaces,
        leftBarFeaturedRaces: action.payload.featuredRaces,
        loading: false,
        openSideMenu: state.openSideMenu
      };
    }
    case "LHN_REQUEST_FAIL": {
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
}
