// @flow

import classNames from "classnames";
import React, { type Element } from "react";
import style from "./style.css";

type Props = {
  customClass?: string,
  shouldCloseOnItemClick?: boolean,
  onClickFn?: Function,
  children?: Element<*>,
  closeDropdownCallBack?: Function
};

const ContainerItem = (props: Props) => {
  const onClick = () => {
    if (typeof props.onClickFn === "function") {
      props.onClickFn();
    }
    // props.shouldCloseOnItemClick - to close or not dropdown. By default is true - to close.
    if (
      props.shouldCloseOnItemClick &&
      typeof props.closeDropdownCallBack === "function"
    ) {
      props.closeDropdownCallBack();
    }
    return this;
  };

  const customClass = props.customClass
    ? { [props.customClass]: props.customClass }
    : {};

  const liStyle = classNames({
    [style.trackSelectorListLi]: true,
    ...customClass
  });

  return (
    <li className={liStyle}>
      <a onClick={onClick} role="button" tabIndex={0}>
        {props.children}
      </a>
    </li>
  );
};

ContainerItem.defaultProps = {
  shouldCloseOnItemClick: true,
  customClass: "",
  children: [],
  onClickFn: null,
  closeDropdownCallBack: null
};

export default ContainerItem;
