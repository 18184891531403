module.exports = {
  options: [
    {
      code: "FAV",
      name: "Only show favorite tracks"
    }
  ],
  optionsAlias: {
    FAV: "FAV"
  }
};
