// @flow

export type Actions = {
  type: "HIDE_QUICK_LINKS" | "SHOW_QUICK_LINKS"
};

export const hideQuickLinks = (): Actions => ({
  type: "HIDE_QUICK_LINKS"
});

export const showQuickLinks = (): Actions => ({
  type: "SHOW_QUICK_LINKS"
});
