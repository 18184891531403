// @flow
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as mediator from "@tvg/mediator-classic/src";
// $FlowFixMe
import { Scrollbars } from "react-custom-scrollbars";
import LeftBarRace from "../LeftBarRace";
import style from "./style.css";
import updateRacesMTP from "../../factories/raceMtpUpdate";

const SHOW_MORE_LABEL: string = "Show More";
const SHOW_LESS_LABEL: string = "Show Less";
const FEATURED_RACES_LIMIT: number = 3;
const RACES_HEIGHT: number = 57;
const HEADER_HEIGHT: number = 32;

const calcFeatureRacesMaxHeight = (races): Object => {
  if (races && races.length > 3) {
    const maxHeight = races.length * RACES_HEIGHT + HEADER_HEIGHT;
    return { maxHeight: `${maxHeight}px` };
  }
  const maxHeight = 3 * RACES_HEIGHT + HEADER_HEIGHT;
  return { maxHeight: `${maxHeight}px` };
};

const calcFeatureRacesHeight = (): Object => ({
  height: `calc(100% - ${HEADER_HEIGHT}px )`
});

const prepRaceList = (raceInfo): React$Element<any> | null =>
  raceInfo ? (
    // $FlowFixMe
    <LeftBarRace
      key={`featureRaces${raceInfo.trackAbbr}${raceInfo.raceNumber}`}
      raceInfo={raceInfo}
    />
  ) : null;

const featureRaceList = (updatedRaces, showMore): React$Element<any> =>
  showMore ? (
    <Scrollbars style={calcFeatureRacesHeight()} autoHide>
      <ul className={style.featuredRaceList}>{updatedRaces}</ul>
    </Scrollbars>
  ) : (
    <ul className={style.featuredRaceList}>{updatedRaces}</ul>
  );

type Props = {
  racesMTP: Array<Object>,
  leftBarFeaturedRaces: Array<Object>,
  featureToggles: Array<Object>
};

// $FlowFixMe
export class LeftBarFeatureRaces extends Component {
  constructor() {
    super();
    _.bindAll(this, ["getShowMoreButton", "showMoreLessLabel", "setShowMore"]);
  }

  state = { showMore: false };

  getShowMoreButton(): React$Element<any> | string {
    const { leftBarFeaturedRaces } = this.props;
    return leftBarFeaturedRaces.length > FEATURED_RACES_LIMIT ? (
      <button className={style.showHideButton} onClick={this.setShowMore}>
        {this.showMoreLessLabel()}
      </button>
    ) : (
      ""
    );
  }

  setShowMore(): void {
    // Gtm Event
    mediator.dispatch("LHN_SHOW_MORE_LESS", {
      // $FlowFixMe
      action: !this.state.showMore ? "Show More" : "Show less"
    });

    // $FlowFixMe
    this.setState({ showMore: !this.state.showMore });
  }

  showMoreLessLabel(): string {
    // $FlowFixMe
    return this.state.showMore ? SHOW_LESS_LABEL : SHOW_MORE_LABEL;
  }

  props: Props;

  render(): React$Element<any> | null {
    let limitLHNRacesTo120m = _.find(this.props.featureToggles, [
      "name",
      "limitLHNRacesTo120m"
    ]);
    limitLHNRacesTo120m = _.get(limitLHNRacesTo120m, "enabled", false);
    const { leftBarFeaturedRaces, racesMTP } = this.props;
    let returnValue = null;
    const updatedRaces = updateRacesMTP(
      leftBarFeaturedRaces,
      racesMTP,
      limitLHNRacesTo120m
    ).map(prepRaceList);

    if (updatedRaces && updatedRaces.length) {
      // $FlowFixMe
      const showMore = this.state.showMore ? style.showMore : style.showLess;
      returnValue = (
        <div
          style={calcFeatureRacesMaxHeight(updatedRaces)}
          className={classNames(style.featuredWrapper, showMore)}
          featuredRacestvgLiveRacestvgLiveRaces
          data-qa-label="featuredRaces"
        >
          <div className={style.featuredTitle}>
            <span className={style.featuredTitleLabel}>Featured</span>
            {this.getShowMoreButton()}
          </div>
          {
            // $FlowFixMe
            featureRaceList(updatedRaces, this.state.showMore, racesMTP)
          }
        </div>
      );
    }
    return returnValue;
  }
}

export default connect((store) => ({
  leftBarFeaturedRaces: store.lhnRaces.leftBarFeaturedRaces,
  featureToggles: store.lhnFeatureToggles.featureToggles
}))(LeftBarFeatureRaces);
