// @flow

export default class VoidStorage {
  storage: { [string]: * };

  constructor() {
    this.storage = {};
  }

  getItem(item: string) {
    return this.storage[item];
  }

  removeItem(item: string) {
    return Object.keys(this.storage).reduce((acc, key) => {
      if (key !== item) {
        acc[key] = this.storage[key];
      }
      return acc;
    }, {});
  }

  setItem(name: string, value: string) {
    this.storage[name] = value;
  }

  clear() {
    this.storage = {};
  }
}

// export default { VoidStorage };
