module.exports = {
  options: [
    {
      code: "T",
      name: "Thoroughbred",
      totalRaces: 0
    },
    {
      code: "H",
      alias: "H",
      name: "Harness",
      totalRaces: 0
    },
    {
      code: "Q",
      name: "Quarter Horse",
      totalRaces: 0
    },
    {
      code: "G",
      name: "Greyhounds",
      totalRaces: 0
    }
  ],
  optionsAlias: {
    T: "TB",
    H: "H",
    Q: "QH",
    G: "GH",
    FAV: "ONLY FAVORITES"
  }
};
