import { FavoriteTrackWithId } from "@tvg/ts-types/User";
import { TrackData } from "../types";

interface SetFetchedWagerProfile {
  type: "SET_RACETRACKS_FETCHED_WAGER_PROFILE";
  payload: {
    fetchedWagerProfile: string;
  };
}

export type SetSeoTrackList = {
  type: "SET_SEO_TRACK_LIST";
  payload: Array<TrackData>;
};

export type SetFromQuickLinks = {
  type: "SET_FROM_QUICK_LINKS";
  payload: boolean;
};

export type SetTodaysTracks = {
  type: "SET_TODAYS_TRACKS";
  payload: Array<string>;
};

export type SetUserFavoriteTracks = {
  type: "SET_USER_FAVORITE_TRACKS";
  payload: {
    trackCode?: string;
    favoriteTracks: string[];
  };
};

export type ClearUserFavoriteTracks = {
  type: "CLEAR_USER_FAVORITE_TRACKS";
};

export type SetUserFavoriteTrack = {
  type: "SET_FAVORITE_TRACK";
  payload: {
    trackCode: string;
    favoriteId: number;
  };
};

export type SetUserFavoriteTracksWithIdActionType = {
  type: "SET_USER_FAVORITE_TRACKS_WITH_FAVORITE_ID";
  payload: {
    favoriteTracksWithId: FavoriteTrackWithId;
  };
};

export type RemoveFavoriteTrackActionType = {
  type: "REMOVE_FAVORITE_TRACK";
  payload: {
    trackCode: string;
  };
};

export type Actions =
  | SetFetchedWagerProfile
  | SetSeoTrackList
  | SetFromQuickLinks
  | SetTodaysTracks
  | SetUserFavoriteTracks
  | SetUserFavoriteTrack
  | ClearUserFavoriteTracks
  | SetUserFavoriteTracksWithIdActionType
  | RemoveFavoriteTrackActionType;

export const setFetchedWagerProfile = (
  fetchedWagerProfile: string
): SetFetchedWagerProfile => ({
  type: "SET_RACETRACKS_FETCHED_WAGER_PROFILE",
  payload: { fetchedWagerProfile }
});

export const setSeoTrackList = (
  seoTrackList: Array<TrackData>
): SetSeoTrackList => ({
  type: "SET_SEO_TRACK_LIST",
  payload: seoTrackList
});

export const setFromQuickLinks = (
  fromQuickLinks: boolean
): SetFromQuickLinks => ({
  type: "SET_FROM_QUICK_LINKS",
  payload: fromQuickLinks
});

export const setTodaysTracks = (
  todaysTracks: Array<string>
): SetTodaysTracks => ({
  type: "SET_TODAYS_TRACKS",
  payload: todaysTracks
});

export const setUserFavoriteTracks = (
  favoriteTracks: string[]
): SetUserFavoriteTracks => ({
  type: "SET_USER_FAVORITE_TRACKS",
  payload: { favoriteTracks }
});

export const setUserFavoriteTrack = (
  trackCode: string,
  favoriteId: number
): SetUserFavoriteTrack => ({
  type: "SET_FAVORITE_TRACK",
  payload: { trackCode, favoriteId }
});

export const setUserFavoriteTracksWithId = (
  favoriteTracksWithId: FavoriteTrackWithId
): SetUserFavoriteTracksWithIdActionType => ({
  type: "SET_USER_FAVORITE_TRACKS_WITH_FAVORITE_ID",
  payload: { favoriteTracksWithId }
});

export const removeFavoriteTrack = (
  trackCode: string
): RemoveFavoriteTrackActionType => ({
  type: "REMOVE_FAVORITE_TRACK",
  payload: { trackCode }
});

export const clearUserFavoriteTracks = (): ClearUserFavoriteTracks => ({
  type: "CLEAR_USER_FAVORITE_TRACKS"
});
