import React from "react";
import { Route, Routes } from "react-router-dom";
import LHNComp from "./lhn/containers/LHN";

export default (props) => (
  <Routes>
    <Route path="/registration" element={<LHNComp {...props} mode="hide" />} />
    <Route
      path="/blocked-country"
      element={<LHNComp {...props} mode="hide" />}
    />
    <Route
      path="/reset-credentials"
      element={<LHNComp {...props} mode="hide" />}
    />
    <Route
      path="/credentials-reset"
      element={<LHNComp {...props} mode="hide" />}
    />
    <Route
      path="/forgot-credentials"
      element={<LHNComp {...props} mode="forgot-credentials" />}
    />
    <Route
      path="/greyhounds"
      element={<LHNComp {...props} mode="greyhounds" />}
    />
    <Route
      path="/responsible-gaming"
      element={<LHNComp {...props} mode="hide" />}
    />
    <Route path="/unsubscribe" element={<LHNComp {...props} mode="hide" />} />
    <Route path="/wallet" element={<LHNComp {...props} mode="hide" />} />
    <Route path="*" element={<LHNComp {...props} mode="standard" />} />
  </Routes>
);
