// @flow
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import LeftBarRace from "../LeftBarRace";
import style from "./style.css";
import transitions from "./transitions.css";
import NoRaces from "../NoRaces";

type Props = {
  tracks: Array<Object>,
  leftBarRacesLoading: boolean
};

const tranformGHTracksToRaces = (tracks) => {
  let result = [];
  _.attempt(() => {
    result = _.sortBy(
      _.filter(tracks, (track) => track.races.length)
        .map((track) => track.races[0])
        .map((race) => ({
          trackAbbr: _.get(race, "track.code"),
          trackName: _.get(race, "track.name"),
          country: _.get(race, "location.country"),
          perfAbbr: _.get(race, "track.perfAbbr"),
          raceNumber: _.get(race, "number"),
          mtp: _.get(race, "mtp"),
          postTime: _.get(race, "postTime"),
          raceStatus: _.get(race, "status"),
          isGreyhound: true
        })),
      ["mtp"]
    );
  });
  return result;
};

// $FlowFixMe
export class GHRaces extends Component {
  /* static defaultProps = {
    leftBarRaces: [],
  }; */
  props: Props;

  genRaceList() {
    const { tracks } = this.props;
    let returnValue = "";
    const races = tranformGHTracksToRaces(tracks);
    if (races.length) {
      returnValue = (
        <TransitionGroup
          className={style.raceList}
          exit
          enter={false}
          appear={false}
          component="ul"
        >
          {races.map((raceInfo) => (
            <CSSTransition
              classNames={{
                exit: transitions.leave,
                exitActive: transitions.leaveActive
              }}
              timeout={{ exit: 600 }}
            >
              {
                // $FlowFixMe
                <LeftBarRace
                  key={`leftBarRace${raceInfo.trackAbbr}-${raceInfo.raceNumber}`}
                  raceInfo={raceInfo}
                />
              }
            </CSSTransition>
          ))}
        </TransitionGroup>
      );
    } else if (!races.length && !this.props.leftBarRacesLoading) {
      // $FlowFixMe
      returnValue = [<NoRaces />];
    }

    return returnValue;
  }

  render() {
    const { leftBarRacesLoading } = this.props;

    return (
      <div className="nextRacesWrapper">
        <div
          className={classNames(
            style.loadingLayer,
            !leftBarRacesLoading ? style.hiddenLoading : ""
          )}
        />
        {this.genRaceList()}
      </div>
    );
  }
}
export default connect((store) => ({
  leftBarRacesLoading: store.lhnRaces.leftBarRacesLoading
}))(GHRaces);
