// @flow

import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const DesktopWrapper = styled.div`
  background-color: ${buildColor("blue", "900")};
  padding: 0 0 8px;
  ${(props) =>
    props.hasNoQuickLinks
      ? css`
          padding: 0 0 4px 0;
        `
      : css`
          padding: 0 0 8px 0;
        `}
`;

const desktopContainerPosition = (isExpanded) =>
  !isExpanded
    ? css`
        top: 56px;
      `
    : css`
        padding-bottom: 8px;

        @media (max-width: 1024px) {
          padding: 4px 12px 12px;
        }
      `;

const containerPosition = (device, isExpanded) => {
  switch (device) {
    case "mobile":
      return css`
        ${!isExpanded
          ? css`
              position: sticky;
              top: 48px;
              background-color: ${buildColor("blue", "700")};
            `
          : css`
              background-color: ${buildColor("blue", "900")};
            `}
      `;
    case "tablet":
      return css`
        ${isExpanded
          ? css`
              position: absolute;
              background-color: ${buildColor("blue", "900")};
            `
          : css`
              position: fixed;
              background-color: ${buildColor("blue", "700")};
            `}
      `;
    default:
      return css`
        ${desktopContainerPosition(isExpanded)}
        background-color:transparent;
      `;
  }
};

export const Container = styled.nav`
  ${({ isExpanded, device }) =>
    !isExpanded
      ? !device === "desktop" &&
        css`
          > :first-child {
            > :first-child {
              padding-left: 4px;
            }
          }
        `
      : device === "mobile" &&
        css`
          > :first-child {
            > :first-child {
              padding-left: 12px;
            }
          }
        `}

  ${({ vertical, device, isExpanded }) =>
    vertical
      ? css`
          background-color: ${buildColor("white", "10")};
          border-radius: 4px;
        `
      : css`
          width: 100%;
          ${containerPosition(device, isExpanded)}
          z-index: 2;
        `}
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      &:hover,
      &:active {
        background-color: ${buildColor("white", "10")};
      }
    `}
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  ${({ vertical, isExpanded, device }) =>
    vertical
      ? css`
          border-radius: 2px;
          flex-direction: column;
          padding: 0;
          margin: 0;
          ${Item} {
            flex: 1 0 40px;
            align-items: stretch;
            justify-content: stretch;
            padding: 0 4px;

            &:first-child {
              border-radius: 4px 4px 0 0;
            }

            &:last-child {
              border-radius: 0 0 4px 4px;
            }
          }
        `
      : css`
          flex-wrap: nowrap;
          overflow: auto;
          ${device !== "mobile"
            ? css`
                width: calc(100% + 8px);
              `
            : isExpanded &&
              css`
                &::after {
                  content: "";
                  display: inline-block;
                  min-width: 4px;
                  height: 112px;
                }
              `}

          ${isExpanded
            ? device !== "desktop" &&
              css`
                padding: 12px;
              `
            : css`
                padding: 8px 0;
              `}

          ${Item} {
            flex: 0 0 auto;
            ${!isExpanded
              ? css`
                  height: 32px;
                `
              : css`
                  ${device === "mobile"
                    ? css`
                        width: 141px;
                        margin-right: 8px;
                      `
                    : css`
                        width: 20%;
                      `};
                `}
            border-radius: 2px;
          }
        `};
`;
