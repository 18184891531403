import { gql } from "@apollo/client";

export const GET_LHN_RACES = gql`
  query getLHNRaces(
    $wagerProfile: String
    $sortByName: TrackListSort
    $sortByPostTime: RaceListSort
    $filterMTP: RaceListFilter
    $allTrackClasses: TrackListFilter
    $isGreyhound: TrackListFilter
    $statusAndGreyhounds: RaceListFilter
    $page: Pagination
  ) {
    tracks: tracks(
      profile: $wagerProfile
      sort: $sortByName
      filter: $allTrackClasses
    ) {
      currentRace {
        number
        postTime
        mtp
        status {
          code
        }
        mtp
        video {
          liveStreaming
          streams
        }
        type {
          name
          code
        }
      }
      featured
      name
      code
      numberOfRaces
      perfAbbr
    }
    nextRacesGreyhounds: tracks(profile: $wagerProfile, filter: $isGreyhound) {
      races(filter: $statusAndGreyhounds, sort: $sortByPostTime, page: $page) {
        track {
          id
          code
          perfAbbr
          name
          location {
            country
          }
          numberOfRaces
        }
        number
        mtp
        postTime
        status {
          code
        }
      }
    }
    mtpGlobalUpdate: races(
      filter: $filterMTP
      profile: $wagerProfile
      sort: $sortByPostTime
    ) {
      number
      mtp
      trackCode
      trackName
      postTime
      track {
        id
        perfAbbr
      }
      status {
        code
      }
    }
  }
`;

export default GET_LHN_RACES;
