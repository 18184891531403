// @flow
import tvgConf from "@tvg/conf";

let host: string = "";
const devMode = process.env.NODE_ENV === "DEVELOPMENT";

export default {
  setHost: (req: Object): void => {
    if (req) {
      host = req.hostname;
    } else if (window) {
      host = window.location.hostname;
    }
  },
  getServiceUrl: (path: string): string =>
    devMode ? tvgConf("").config(path) : tvgConf(host).config(path),
  getBrand: (): string => tvgConf(host).brand
};
