// @flow
import React, { Component } from "react";
import classnames from "classnames";
import _ from "lodash";
import FilterIcon from "@tvg/ui-bootstrap/assets/svg/filters.svg";
import style from "./style.css";

type Props = {
  savedFilters: string,
  isFirstVisit: boolean,
  openCloseHandler: () => void,
  isFilterOpened: boolean
};

class RaceFilterButtonTrigger extends Component {
  constructor(props: Props) {
    super(props);
    _.bindAll(this, [
      "hasFilterEnabled",
      "renderFilters",
      "renderButton",
      "isFirstVisit",
      "isFilterOpened",
      "getFiltersBreed"
    ]);
  }

  getFiltersBreed() {
    return _.get(this.props, "savedFilters") || "";
  }

  props: Props;

  isFilterOpened() {
    return this.props.isFilterOpened;
  }

  isFirstVisit() {
    return this.props.isFirstVisit !== "false";
  }

  hasFilterEnabled() {
    return !!this.getFiltersBreed().length;
  }

  renderFilters() {
    return (
      <dl className={style.filters}>
        <dt>FILTERS:</dt>
        <dd>{this.props.savedFilters || `-`}</dd>
      </dl>
    );
  }

  renderButton(type?: string) {
    const ButtonClasses = classnames({
      [style.FilterButton]: true,
      [style.FilterButton__compact]:
        type === "compact" || this.isFilterOpened(),
      [style.FilterButton__new]:
        type !== "compact" && !this.isFilterOpened() && this.isFirstVisit()
    });

    const displayLabel = () => !this.isFilterOpened() && type !== "compact";

    return (
      <button
        className={ButtonClasses}
        onClick={this.props.openCloseHandler}
        data-qa-label="filterButton"
      >
        <span>
          <FilterIcon className={style.FilterButtonIcon} />
          {displayLabel() && "Filter"}
        </span>
      </button>
    );
  }

  render() {
    return (
      <div className={style.container}>
        {this.hasFilterEnabled() || this.isFilterOpened() ? (
          <div className={style.containerWithFilters}>
            {this.renderFilters()}
            {this.renderButton("compact")}
          </div>
        ) : (
          this.renderButton()
        )}
      </div>
    );
  }
}

export default RaceFilterButtonTrigger;
