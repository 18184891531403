import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useDebounce } from "../../../hooks";
import { Layout, Placement } from "../types";

export interface GetCoordinates {
  triggerLayout: Layout;
  placement: Placement;
  windowWidth: number;
  windowHeight: number;
}

interface Coordinates {
  top: number;
  left?: number;
  right?: number;
}

const DEFAULT_COORDINATES: Coordinates = {
  left: 0,
  top: 0
};

const useGetCoordinates = ({
  triggerLayout,
  placement,
  windowWidth
}: GetCoordinates) => {
  const {
    x: triggerX = 0,
    y: triggerY = 0,
    width: triggerWidth,
    height: triggerHeight
  } = triggerLayout;

  const [coordinates, setCoordinates]: [
    Coordinates,
    Dispatch<SetStateAction<Coordinates>>
  ] = useState(DEFAULT_COORDINATES);
  const [isReady, setIsReady]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState(false as boolean);
  const debounce = useDebounce();

  const getHorizontalCoordinates = (contentWidth: number) => {
    if (triggerWidth >= contentWidth) {
      return triggerX + (triggerWidth - contentWidth) / 2;
    }
    return triggerX - contentWidth / 2 + triggerWidth / 2;
  };

  const getVerticalCoordinates = (contentHeight: number) => {
    if (triggerHeight >= contentHeight) {
      return triggerY + (triggerHeight - contentHeight) / 2;
    }
    return triggerY - contentHeight / 2 + triggerHeight / 2;
  };

  const updateCoordinates = (contentWidth: number, contentHeight: number) => {
    const newCoordinates: Coordinates = { top: triggerY };

    switch (placement) {
      case "top-left":
        newCoordinates.top -= contentHeight;
        newCoordinates.right =
          windowWidth - (triggerX + triggerWidth) + triggerWidth / 2;
        break;
      case "top-mid":
        newCoordinates.top -= contentHeight;
        newCoordinates.left = getHorizontalCoordinates(contentWidth);
        break;
      case "top-right":
        newCoordinates.top -= contentHeight;
        newCoordinates.left = triggerX + triggerWidth / 2;
        break;
      case "left":
        newCoordinates.top = getVerticalCoordinates(contentHeight);
        newCoordinates.right =
          windowWidth - (triggerX + triggerWidth) + triggerWidth;
        break;
      case "right":
        newCoordinates.top = getVerticalCoordinates(contentHeight);
        newCoordinates.left = triggerX + triggerWidth;
        break;
      case "bottom-left":
        newCoordinates.top += triggerHeight;
        newCoordinates.right =
          windowWidth - (triggerX + triggerWidth) + triggerWidth / 2;
        break;
      case "bottom-mid":
        newCoordinates.top += triggerHeight;
        newCoordinates.left = getHorizontalCoordinates(contentWidth);
        break;
      case "bottom-right":
        newCoordinates.top += triggerHeight;
        newCoordinates.left = triggerX + triggerWidth / 2;
        break;
      case "drop-down":
        newCoordinates.top += triggerHeight;
        newCoordinates.left = triggerX;
        break;
      default:
        break;
    }

    setCoordinates({
      ...newCoordinates,
      ...("left" in newCoordinates &&
      newCoordinates.left &&
      newCoordinates.left < 0
        ? { left: 0 }
        : {}),
      ...("right" in newCoordinates &&
      newCoordinates.right &&
      newCoordinates.right < 0
        ? { right: 0 }
        : {})
    });
  };

  // use debounce since onLayout is triggered as the Content changes position
  // and we only want to set ready on the last position update
  useEffect(
    debounce(() => setIsReady(true), 32),
    [coordinates.left, coordinates.top, coordinates.right]
  );

  useEffect(
    () => () => {
      setIsReady(false);
    },
    []
  );

  return {
    style: { ...coordinates },
    isReady,
    updateCoordinates
  };
};

export default useGetCoordinates;
