// @flow
import React, { Component } from "react";
import _ from "lodash";
import Checkbox from "@tvg/ui-bootstrap/components/Form/Checkbox";
import { getContextStyle } from "../../factories/styleContext";
import styleDesktop from "./style.desktop.css";
import styleMobile from "./style.mobile.css";

type Props = {
  context: string,
  options: Array,
  values: Array,
  toggleOption: (par: string) => void
};

class RaceFilterOptionsGroup extends Component {
  constructor(props: Props) {
    super(props);

    this.style = getContextStyle(this.props.context, styleDesktop, styleMobile);

    _.bindAll(this, ["renderOptions", "isCheckedHandler"]);
  }

  props: Props;

  isCheckedHandler(code: string) {
    return this.props.values.indexOf(code) !== -1;
  }

  renderOptions() {
    return this.props.options.map((option) => (
      <li key={`option-${option.code}`} data-qa-label="checkSelector">
        <Checkbox
          id={`option-${option.code}`}
          mobile={this.props.context === "mhdr"}
          checked={this.isCheckedHandler(option.code)}
          onChange={() => this.props.toggleOption(option.code)}
        >
          {option.name}
        </Checkbox>
      </li>
    ));
  }

  render() {
    return <ul className={this.style.container}>{this.renderOptions()}</ul>;
  }
}

export default RaceFilterOptionsGroup;
