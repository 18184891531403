// @flow

import React from "react";
import classNames from "classnames";
import moment from "moment";
import MtpNear from "../../assets/svg/mtp-near.svg";
import MtpMed from "../../assets/svg/mtp-med.svg";
import MtpFar from "../../assets/svg/mtp-far.svg";
import MtpCancelled from "../../assets/svg/mtp-cancelled.svg";
import Cup from "../../assets/svg/cup.svg";
import style from "./style.css";

type Props = {
  mtp: number,
  postTime: string,
  statusCode: string,
  inline: boolean,
  iconClass: string,
  containerClass: string,
  minutesClass: string,
  liveClass: string,
  timeClass: string
};

type Context = {
  device: string
};

export const getMtp = (
  mtp: number,
  postTime: string,
  statusCode: string,
  inline: boolean,
  iconClass: string,
  minutesClass: string,
  liveClass: string,
  timeClass: string
) => {
  let icon;
  let iconContainerClass;
  let timeLabel;
  const iconsClasses = inline
    ? classNames([style.iconMtp, style.inline, iconClass])
    : classNames([style.iconMtp, iconClass]);

  const threshold = 60;

  switch (statusCode) {
    case "SK": {
      // LIVE
      timeLabel = (
        <div className={classNames(style.live, liveClass)}>
          <div>RACE</div>
          <div>OFF</div>
        </div>
      );
      break;
    }
    case "RO": {
      // RESULTS
      icon = <Cup className={classNames([style.fillGrey], iconsClasses)} />;
      timeLabel = <div className={style.label}>RESULT</div>;
      break;
    }
    case "C": {
      // CANCELLED
      icon = <MtpCancelled className={iconsClasses} />;
      iconContainerClass = !inline ? "cancelled" : "";
      timeLabel = <div className={style.label}>CANCELLED</div>;
      break;
    }
    default: {
      if (mtp > threshold) {
        // POST TIME
        timeLabel = (
          <div className={classNames(style.time, timeClass)}>
            <div>{moment(postTime).format("hh:mm")}</div>
            <div>{moment(postTime).format("a")}</div>
          </div>
        );
      } else {
        // MTP (Default: far)
        icon = <MtpFar className={iconsClasses} />;
        iconContainerClass = !inline ? "mtp-far" : "";
        timeLabel = (
          <div className={classNames(style.minutes, minutesClass)}>{mtp}m</div>
        );

        if (mtp <= 5) {
          // near
          icon = <MtpNear className={iconsClasses} />;
          iconContainerClass = !inline ? "mtp-near" : "";
        } else if (mtp <= 15) {
          // med
          icon = <MtpMed className={iconsClasses} />;
          iconContainerClass = !inline ? "mtp-med" : "";
        }
      }
      break;
    }
  }

  return { icon, iconContainerClass, timeLabel };
};

const Mtp = (props: Props, context: Context) => {
  const mtp = getMtp(
    props.mtp,
    props.postTime,
    props.statusCode,
    props.inline,
    props.iconClass,
    props.minutesClass,
    props.liveClass,
    props.timeClass
  );

  const mtpClass = classNames(
    {
      [style.mtp]: true,
      [style.mobile]: context.device === "mobile",
      [style[mtp.iconContainerClass]]: mtp.iconContainerClass
    },
    props.containerClass
  );

  return (
    <div className={mtpClass}>
      {mtp.icon ? mtp.icon : null}
      {mtp.timeLabel}
    </div>
  );
};

Mtp.defaultProps = {
  inline: false,
  iconClass: null,
  containerClass: null,
  minutesClass: null,
  liveClass: null,
  timeClass: null
};

export default Mtp;
