// @flow
import type { QuickLink } from "@tvg/types/Links";
import { type Actions } from "../actions/content";

export type QuickLinksState = {
  links: QuickLink[],
  data: QuickLink[]
};

export const initialState: QuickLinksState = {
  links: [],
  data: []
};

export default function reducer(
  state: QuickLinksState = initialState,
  action: Actions
): QuickLinksState {
  switch (action.type) {
    case "STORE_QUICK_LINKS":
      return {
        links: action.payload,
        data: state.data
      };
    case "STORE_QUICK_LINKS_DATA":
      return {
        links: state.links,
        data: action.payload
      };
    default:
      return state;
  }
}
