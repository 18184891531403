/**
 * Wrapper to video component
 */

// @flow
import React, { Component } from "react";
import type { Element } from "react";
import _ from "lodash";

import Video from "../Video/index";
import ForwardArrow from "../../assets/svg/arrow-forward.svg";
import TvgLogoWhite from "../../assets/images/tvg-logo-white.svg";
import Tvg2LogoWhite from "../../assets/images/tvg2-logo-white.svg";
import VideoIcon from "../../assets/svg/mtp-wvideo.svg";
import style from "./style.css";

/* eslint-disable */
type Track = {
  name: string,
  trackAbbr: string,
  perfAbbr: string,
  nextRace: number,
  streamName: string,
  timeLabel: string,
  status: string,
  channel: string,
  isHD: boolean
};
/* eslint-enable */

// flow is stupid and made me do these two types

type CustomElement = {
  getBoundingClientRect: Function,
  clientHeight: number
};

type CustomMouseEvent = {
  target: CustomElement,
  currentTarget: CustomElement,
  clientY: number
};

type TrackCallback = (a: ?Object) => void;

/* eslint-enable */

type Props = {
  id: number,
  /**
   * race id
   */
  selectedTrack?: Track,
  /**
   * Flash Video
   */
  isFlash?: boolean,
  /**
   * Login State
   */
  isLogged: boolean,
  /*
   * Has tracks available
   */
  hasTracks: boolean,
  /**
   * Function that allows communication with the dropdown
   */
  toggleDropDownCallback: Function,
  /**
   * Login callBack
   */
  loginCallback?: TrackCallback,
  /**
   * bet callBack
   */
  betCallback?: TrackCallback, // eslint-disable-line
  /**
   * Custom style for dropdown
   */
  dropdownButtonClass?: string, // eslint-disable-line
  /*
   * Popout video player
   */
  onPopOut: () => mixed
};

type State = {
  selectedTrack?: ?Track,
  isFlash?: boolean,
  isLogged: boolean
};

const DROPSIDE_HEIGHT = 360;
/**
 * show tvg channel icon
 */
const renderChannel = (channel: ?string) => {
  switch (channel) {
    case "1":
      return <TvgLogoWhite width="40" />;
    case "2":
      return <Tvg2LogoWhite width="50" />;
    default:
      return null;
  }
};

const calcUpsideDown = (event: CustomMouseEvent): boolean => {
  const clickY = event.clientY;
  const viewPortHeight =
    document.documentElement &&
    Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

  return clickY + DROPSIDE_HEIGHT > parseInt(viewPortHeight, 10);
};

const calcDropSideHeight = (event: CustomMouseEvent): number => {
  const target = event.currentTarget;
  const lhnTabsHeader = document.querySelector("#tabsWrapperHeader");
  const headerHeight = lhnTabsHeader ? lhnTabsHeader.clientHeight : 0;
  const eventData = event && target;
  const thisButtonHeight = eventData ? target.clientHeight : 0;
  const isUpsideDown = calcUpsideDown(event);

  const totalOffset: number = isUpsideDown
    ? thisButtonHeight + DROPSIDE_HEIGHT - 26
    : thisButtonHeight + 4 + Math.floor(headerHeight / 2);
  return eventData
    ? event.currentTarget.getBoundingClientRect().top - totalOffset
    : 0;
};

/**
 * TVG Video Wrapper Component
 *
 * This component will encapsulate the video component and will add it some interactions.
 * will allow to change live Tracks, go too betSlip, Show Login container and popout video
 *
 */
export default class VideoWrapper extends Component {
  /** ---- Component Types ---- */

  static defaultProps = {
    hasTracks: false
  };

  constructor(props: Props) {
    super(props);
    _.bindAll(this, [
      "getVideoStream",
      "getHeader",
      "getLoggedInRender",
      "renderHeaderLabelSelectedTrack",
      "renderHeaderLabelNextRace",
      "showHideDropdown",
      "getLogoutRender",
      "selectVideoRace",
      "handleCallback"
    ]);

    this.state = {
      selectedTrack: this.props.selectedTrack,
      isFlash: this.props.isFlash,
      isLogged: this.props.isLogged
    };
  }

  state: State;

  componentWillReceiveProps(nextProps: Props) {
    const selectedTrack =
      this.state.selectedTrack !== nextProps.selectedTrack
        ? nextProps.selectedTrack
        : this.state.selectedTrack;

    this.setState({
      isFlash: nextProps.isFlash,
      isLogged: nextProps.isLogged,
      selectedTrack
    });
  }

  /**
   * Render Video Component
   */
  getVideoStream(): Element<*> {
    if (this.state.selectedTrack) {
      return (
        <div className={style.videoContainer}>
          <Video
            videoClass={style.videoStyle}
            streamName={this.state.selectedTrack.streamName}
            isFlash={this.state.isFlash}
            isHD={this.state.selectedTrack.isHD}
            userLoggedIn={this.state.isLogged}
            onPopOut={this.props.onPopOut}
          />
        </div>
      );
    }
    return (
      <div className={style.videoContainer}>
        <div className={style.disableOverlay}>
          <VideoIcon />
        </div>
      </div>
    );
  }

  /**
   * Render Header component
   */
  getHeader(): ?Element<*> {
    let headerLabel = (
      <span className={style.headerLabel}>
        {this.props.hasTracks ? "NO TRACK SELECTED" : "NO AVAILABLE TRACKS"}
      </span>
    );

    if (_.get(this.state, "selectedTrack.nextRace")) {
      headerLabel = this.renderHeaderLabelNextRace();
    } else if (_.get(this.state, "selectedTrack")) {
      headerLabel = this.renderHeaderLabelSelectedTrack();
    }
    return <div className={style.header}>{headerLabel}</div>;
  }

  /**
   * Render logged in container
   */
  getLoggedInRender(): Element<*> {
    return (
      <div className={style.videoWrapper}>
        {this.getHeader()}
        {this.getVideoStream()}
      </div>
    );
  }

  /**
   * Render logged out container
   */
  getLogoutRender(): Element<*> {
    return (
      <button
        className={style.loginVideoWrapper}
        onClick={(e) => this.handleCallback(e, this.props.loginCallback)}
      >
        <div className={style.overlay}>
          <VideoIcon />
          <p className={style.loginLabel}>
            Please,
            <span className={style.loginLink}>Log in</span>
            to watch videos
          </p>
        </div>
      </button>
    );
  }

  /**
   * Select Video Race
   */
  selectVideoRace(track: Track): void {
    this.setState({
      selectedTrack: track
    });
  }

  /**
   * Handle function callback
   */
  handleCallback(event: ?Event, callback?: TrackCallback): void {
    if (event) {
      event.preventDefault();
    }

    if (callback) {
      callback(this.state.selectedTrack);
    }
  }

  props: Props;

  /**
   * show and hide track dropDown list
   */
  showHideDropdown(event?: CustomMouseEvent): void {
    const videoPlayerId = this.props.id;
    const topValue = event ? calcDropSideHeight(event) : 0;
    const upsideDownFlag = event ? calcUpsideDown(event) : false;

    this.props.toggleDropDownCallback(videoPlayerId, topValue, upsideDownFlag);
  }

  /**
   * This will render header next race
   */
  renderHeaderLabelNextRace(): ?Element<*> {
    if (!this.state.selectedTrack) {
      return null;
    }
    return (
      <span className={style.headerLabel}>
        {this.state.selectedTrack.name}
        <ForwardArrow className={style.arrow} />
      </span>
    );
  }

  /**
   * This will render header selected track
   */
  renderHeaderLabelSelectedTrack(): ?Element<*> {
    if (!this.state.selectedTrack) {
      return null;
    }
    return (
      <div className={style.headerLabel}>
        <span className={style.liveOn}>Live on</span>
        {_.get(this.state, "selectedTrack.channel")
          ? // $FlowBug
          renderChannel(this.state.selectedTrack.channel)
          : null}
      </div>
    );
  }

  render() {
    if (this.state.isLogged) {
      return this.getLoggedInRender();
    }
    return this.getLogoutRender();
  }
}
