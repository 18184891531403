import React, { Component, PropTypes } from "react"; //eslint-disable-line
import classNames from "classnames";
import styles from "./index.css";

type LoadingSizes = "small" | "medium" | "big";

type Props = {
  /**
   * Show loading or not
   */
  show: boolean,
  /**
   * small | big
   */
  size: LoadingSizes
};

/**
 * TVG Loading Component
 */
class Loading extends Component {
  static defaultProps = {
    size: "medium",
    show: true
  };

  static getLoadingProps(type: LoadingSizes) {
    const dict = {
      big: styles.loadingBig,
      small: styles.loadingSmall,
      medium: styles.loadingMedium
    };

    return dict[type];
  }

  props: Props;

  render() {
    if (!this.props.show) {
      return null;
    }

    const size = this.props.size || "medium";

    return (
      <div
        className={classNames(styles.loading, Loading.getLoadingProps(size))}
        data-qa-label="Loading"
      />
    );
  }
}

export default Loading;
