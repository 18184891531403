// @flow

import React from "react";
import classNames from "classnames";
import style from "./style.css";

type Props = {
  label: string,
  customClass?: string
};

const Label = (props: Props) => {
  const customClass = props.customClass
    ? { [props.customClass]: props.customClass }
    : {};

  const labelStyle = classNames({
    [style.trackSelectorListLabel]: true,
    ...customClass
  });

  return <div className={labelStyle}> {props.label} </div>;
};

Label.defaultProps = {
  customClass: ""
};

export default Label;
