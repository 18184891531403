// @flow
import React from "react";
import Close from "@tvg/ui-bootstrap/assets/svg/close.svg";
import style from "./style.css";

type Props = {
  message: string,
  title: string,
  close: Function<boolean>
};

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
const FavoriteTooltip = (props: Props) => (
  <div className={style.overlay} onClick={props.close} role="tooltip">
    <div className={style.tootipbox}>
      <button className={style.closeButton}>
        <Close />
      </button>
      <h4>{props.title}</h4>
      <p>{props.message}</p>
    </div>
  </div>
);

export default FavoriteTooltip;
