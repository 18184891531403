// @flow
import quickLinks from "@tvg/quick-links/redux-manager";
import lhnRaces from "./lhnRaces";
import lhnProfile from "./lhnProfile";
import lhnFeatureToggles from "./lhnFeatureToggles";
import lhnDropSide from "./lhnDropSide";

export default {
  lhnRaces,
  lhnProfile,
  lhnFeatureToggles,
  lhnDropSide,
  quickLinks
};
