import { get, has, uniqueId } from "lodash";
import type { Promo, RacePanelLink, UserPromotions } from "@tvg/ts-types/Race";
import { QuickLink } from "@tvg/ts-types/Links";
import type { UserOptedInPromos } from "@tvg/ts-types/User";
import type { CPPPromo, QuickLinkPromo } from "@fdr-mar/promos-types/Promos";
import type { Card } from "@fdr-mar/promos-types/Component";
import { isTvg5 } from "@tvg/utils/generalUtils";

const getOptedInPromos = (
  promos: Promo[],
  optedInPromos: UserOptedInPromos,
  isUserLogged: boolean = false
) => {
  let aboveTheLineRacePromo: Promo | null = null;
  let belowTheLineRacePromo: Promo | null = null;

  promos.forEach((promo) => {
    if (promo && promo.isAboveTheLine && aboveTheLineRacePromo === null) {
      aboveTheLineRacePromo = promo;
    } else if (
      promo &&
      !promo.isAboveTheLine &&
      belowTheLineRacePromo === null &&
      has(optedInPromos, `[${promo.rootParentPromoID}]`)
    ) {
      belowTheLineRacePromo = promo;
    }
  });

  if (
    isUserLogged &&
    belowTheLineRacePromo &&
    optedInPromos[(belowTheLineRacePromo as Promo).rootParentPromoID] !==
      undefined
  ) {
    return {
      // @ts-ignore
      ...(belowTheLineRacePromo || {}),
      isOptedIn:
        optedInPromos[(belowTheLineRacePromo as Promo).rootParentPromoID]
    };
  }
  if (
    isUserLogged &&
    aboveTheLineRacePromo &&
    optedInPromos[(aboveTheLineRacePromo as Promo).rootParentPromoID] !==
      undefined
  ) {
    return {
      // @ts-ignore
      ...(aboveTheLineRacePromo || {}),
      isOptedIn:
        optedInPromos[(aboveTheLineRacePromo as Promo).rootParentPromoID]
    };
  }

  return aboveTheLineRacePromo;
};

export const getHasPromo = (
  useIsPromoTagShownFlag: boolean,
  hasPromo: boolean,
  isPromoTagShown: boolean
) => (useIsPromoTagShownFlag ? hasPromo && isPromoTagShown : hasPromo);

export const hasPromoFromGraph = (
  race: RacePanelLink,
  useIsPromoTagShownFlag: boolean = false
) => {
  const hasPromo =
    !!get(race, "promos[0].isAboveTheLine", false) ||
    !!get(race, "userPromotions.length", false);

  const isPromoTagShown =
    get(race, "promos[0].isPromoTagShown", false) ||
    get(race, "userPromotions[0].isPromoTagShown", false);

  const isOptedIn = get(race, "userPromotions[0].optedIn", false);

  return (
    (hasPromo && isOptedIn) ||
    getHasPromo(useIsPromoTagShownFlag, hasPromo, isPromoTagShown)
  );
};

export const getRDAOptinStatus = (race: RacePanelLink) => {
  const userPromotions: Array<UserPromotions> = get(race, "userPromotions", []);
  let aboveTheLine: UserPromotions | null = null;
  let belowTheLine: UserPromotions | null = null;

  userPromotions.forEach((promo) => {
    if (!promo.isAboveTheLine && !belowTheLine) {
      belowTheLine = promo;
    }

    if (promo.isAboveTheLine && !aboveTheLine) {
      aboveTheLine = promo;
    }
  });

  return belowTheLine
    ? get(belowTheLine, "optedIn", false)
    : get(aboveTheLine, "optedIn", false);
};

export const mapCPPPromos = (promos: Array<QuickLinkPromo>): Array<QuickLink> =>
  // @ts-ignore
  promos.map((link) => {
    const url = isTvg5()
      ? `promos/${link.path.cached_url}`
      : `/?promo=${link.path.cached_url}#promos`;

    return {
      id: link.promoId,
      label: link.categoryTitle,
      url,
      imageURL: link.pictureUrl,
      icon: null,
      tag: null
    };
  });

export const serializePromos = (
  promos: CPPPromo[],
  { isQuicklink = false }: { isQuicklink?: boolean } = {}
): Card[] =>
  promos.map((promo) => {
    const promoName = get(promo, "name", "");
    const promoCode = get(promo, "promoCode", "");
    const isOptedIn =
      get(promo, "customerPromotionState.optInState") === "ONGOING" &&
      get(promo, "customerPromotionState.hasAccepted", false);

    const promoImagesBySize = promo.images.sort((a, b) => b.width - a.width);

    const quicklinkImage =
      isQuicklink &&
      (promoImagesBySize.find(({ tag }) => tag === "carousel") ?? "");

    const promoImage =
      quicklinkImage || promoImagesBySize.find(({ tag }) => tag === "square");

    return {
      isOptedIn,
      promoId: uniqueId(),
      categoryTitle: get(promo, "title", ""),
      component: "slot",
      description: get(promo, "description", ""),
      endDate: get(promo, "endDate", ""),
      title: promoName,
      pictureAlt: promoName,
      pictureHeight: 150,
      pictureUrl: promoImage?.url ?? "",
      segment: "all",
      path: {
        url: promoCode,
        cached_url: promoCode,
        linktype: "story"
      }
    };
  });

export default getOptedInPromos;
