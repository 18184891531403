import _ from "lodash";

export default function reducer(
  state = {
    open: false,
    upsideDown: false,
    top: 0,
    videoPlayerId: 3,
    videoWrapperData: {}
  },
  action
) {
  switch (action.type) {
    case "TOGGLE_DROPSIDE": {
      return {
        ...state,
        ...action.payload
      };
    }
    case "TOGGLE_SELECTED_TRACK_VIDEO": {
      const selectedVideoId = state.videoPlayerId;
      const newVideoWrapperData = _.cloneDeep(state.videoWrapperData);
      newVideoWrapperData[selectedVideoId] = action.payload.selectedTrack;
      return {
        ...state,
        videoWrapperData: newVideoWrapperData
      };
    }
    default: {
      return state;
    }
  }
}
