// @flow
export default class VideoRaceInfo {
  name: string;

  trackName: string;

  trackId: string;

  trackAbbr: string;

  perfAbbr: string;

  nextRace: Object | null;

  timeLabel: string;

  streamName: string;

  streamCode: string;

  status: Object;

  featured: boolean;

  channel: string;

  isHD: boolean;

  isFlash: boolean;

  constructor(
    name: string,
    trackAbbr: string,
    perfAbbr: string,
    nextRace: Object | null,
    timeLabel: string,
    streamName: string,
    status: Object,
    featured: boolean,
    channel?: string = "",
    isHD?: boolean = false,
    isFlash?: boolean = true
  ) {
    this.name = name;
    this.trackName = name;
    // $FlowFixMe
    this.trackId = `${trackAbbr}_${nextRace}`;
    this.trackAbbr = trackAbbr;
    this.perfAbbr = perfAbbr;
    this.nextRace = nextRace;
    this.streamName = streamName;
    this.streamCode = streamName;
    this.timeLabel = timeLabel;
    this.status = status;
    this.featured = featured;
    this.channel = channel;
    this.isHD = isHD;
    this.isFlash = isFlash;
  }
}
