import axios from "axios";
import TVGConf from "@tvg/conf";

const tvgHeaders = () => ({
  "content-type": "application/json",
  "x-clientapp": "tvg4",
  "x-tvg-context": TVGConf().context(),
  "x-requested-with": "XMLHttpRequest"
});

export const onError = () => ({ status: "error" });

export const onSuccess = () => ({ status: "success" });

export default (userId, prefName, prefValue) => {
  const requestOptions = {
    method: "put",
    url: `${TVGConf().config().service.uam}/users/${userId}/preferences`,
    headers: tvgHeaders(),
    withCredentials: true,
    data: {
      preferences: [
        {
          description: prefName,
          metadata: prefValue
        }
      ]
    }
  };

  return axios(requestOptions).then(onSuccess).catch(onError);
};
