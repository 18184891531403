// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as mediator from "@tvg/mediator-classic/src";
import _ from "lodash";
// $FlowFixMe
import enhanceWithClickOutside from "react-click-outside";
// $FlowFixMe
import vidWrapperStyle from "@tvg/ui-bootstrap/components/TVGVideoWrapper/style.css";
// $FlowFixMe
import VideoListDropdown from "@tvg/ui-bootstrap/components/TVGVideoDropDown";
import style from "./style.css";
import { type Track, type VideoTrackList } from "../../../../types.flow";

type Props = {
  dispatch: Function,
  videoTrackList: VideoTrackList,
  open: number,
  upsideDown: boolean,
  top: number,
  videoPlayerId: number
};

// $FlowFixMe
export class DropSide extends Component {
  static defaultProps = {
    videoTrackList: [],
    open: false,
    upsideDown: false,
    top: 0,
    videoPlayerId: 3
  };

  constructor(props: Object): void {
    super(props);
    _.bindAll(this, [
      "showHideDropdown",
      "selectVideoRace",
      "handleClickOutside",
      "getList",
      "selectTrackCallBack"
    ]);
  }

  getList(showDropDown: string): React$Element<any> {
    return (
      <VideoListDropdown
        trackList={this.props.videoTrackList}
        selectTrackCallBack={this.selectTrackCallBack}
        customClass={classNames(
          vidWrapperStyle.dropDownList,
          style.customDropDownList,
          style.customStyling,
          showDropDown
        )}
      />
    );
  }

  selectTrackCallBack(track: Track): void {
    this.props.dispatch({
      type: "TOGGLE_SELECTED_TRACK_VIDEO",
      payload: {
        selectedTrack: track,
        selectedVideoId: this.props.videoPlayerId
      }
    });
    this.props.dispatch({
      type: "TOGGLE_DROPSIDE",
      payload: {
        open: false,
        upsideDown: false,
        top: 0,
        videoPlayerId: undefined
      }
    });
    mediator.dispatch("LHN_SCROLL_DISABLE", { disableToggleValue: false });
  }

  /**
   * show and hide track dropDown list
   */
  showHideDropdown(value: boolean): void {
    this.props.dispatch({
      type: "TOGGLE_DROPSIDE",
      payload: {
        open: value,
        upsideDown: false,
        top: 0,
        videoPlayerId: this.props.videoPlayerId
      }
    });
  }

  handleClickOutside() {
    if (this.props.open === true) {
      mediator.dispatch("LHN_SCROLL_DISABLE", { disableToggleValue: false });
      this.showHideDropdown(false);
      this.props.dispatch({
        type: "TOGGLE_DROPSIDE",
        payload: {
          open: false,
          upsideDown: false,
          top: 0,
          videoPlayerId: undefined
        }
      });
    }
  }

  props: Props;

  /**
   * Select Video Race
   */
  selectVideoRace(): void {
    this.showHideDropdown(false);
  }

  render() {
    const showDropDown = this.props.open
      ? vidWrapperStyle.showDropDownList
      : "";
    const showWrapper = showDropDown ? style.dropSideMainWrapperVisible : "";
    return (
      <div
        className={classNames(
          style.dropSideMainWrapper,
          showWrapper,
          this.props.upsideDown ? style.upsideDown : ""
        )}
        style={{ top: `${this.props.top}px` }}
      >
        {this.getList(showDropDown)}
      </div>
    );
  }
}

export default connect((store) => ({
  open: store.lhnDropSide.open,
  upsideDown: store.lhnDropSide.upsideDown,
  top: store.lhnDropSide.top,
  videoPlayerId: store.lhnDropSide.videoPlayerId,
  videoWrapperData: store.lhnDropSide.videoWrapperData
}))(enhanceWithClickOutside(DropSide));
