// @flow
import axios from "axios";
import * as mediator from "@tvg/mediator-classic/src";
import _ from "lodash";
import ServiceUrl from "../../factories/serviceUrl"; // eslint-disable-line
import {
  successRequest,
  failRequest,
  successNoResults
} from "../../actions/LHNRequestActions";
import lhnQuery from "./lhnTracksQuery";
import Poller from "../Poller/Poller";

const LHNInterval: number = 30000;
const STATUS_ORDER: Array<string> = ["SK", "IC", "O"];
const LHNPoller: Object = new Poller();

declare type Request = {
  url: string,
  method: string,
  data: Object
};

declare type Action = {
  type: string,
  payload: Object | Error | boolean | null
};

declare type RaceStatus = {
  code: string
};

declare type RaceInfo = {
  trackName: string,
  country: string,
  perfAbbr: string,
  raceNumber: string,
  mtp: number,
  postTime: string,
  raceStatus: RaceStatus
};

const sortByStatus: Function = (raceList: Array<RaceInfo>): Array<RaceInfo> =>
  _.sortBy(raceList, [
    (race: RaceInfo): number => {
      const statusCode = race.raceStatus.code;
      const orderStatus = STATUS_ORDER.indexOf(statusCode);
      const orderStatusValue =
        orderStatus >= 0 ? orderStatus : STATUS_ORDER.length;

      return race.mtp === 0 ? orderStatusValue : race.mtp + STATUS_ORDER.length;
    }
  ]);

const getImportanceLevel = (level) => (level === "Normal" ? "0" : "1");

const mapRaces = (races = []) =>
  races.map((race) => {
    const tempRace = {
      country: _.get(race, "location.country", ""),
      isGreyhound: race.isGreyhound,
      mtp: race.mtp,
      perfAbbr: _.get(race, "track.perfAbbr", ""),
      postTime: race.postTime,
      raceId: race.id,
      raceNumber: race.number,
      raceStatus: race.status,
      trackAbbr: _.get(race, "track.code", ""),
      trackName: _.get(race, "track.name", ""),
      typeCode: _.get(race, "type.code", ""),
      onTvg: _.get(race, "video.onTvg", false),
      onTvg2: _.get(race, "video.onTvg2", false)
    };

    if (_.get(race, "highlighted")) {
      // $FlowFixMe
      tempRace.importanceLevel = getImportanceLevel(
        _.get(race, "highlighted.style", null)
      );
      // $FlowFixMe
      tempRace.description = _.get(race, "highlighted.description", null);
      // $FlowFixMe
      tempRace.action = _.get(race, "highlighted.action", null);
      // $FlowFixMe
      tempRace.pinnedOrder = _.get(race, "highlighted.pinnedOrder", null);
    }

    return tempRace;
  });

const extractRacesFromTracks = (tracks = []) => {
  const races = tracks.reduce(
    (result, track) => result.concat(track.races),
    []
  );
  return mapRaces(races);
};

const sortFeatureRaces: Function = (
  raceList: Array<RaceInfo>
): Array<RaceInfo> => _.sortBy(raceList, ["pinnedOrder", "postTime"]);

const calcBrand: Function = (wagerProfile): string => {
  switch (wagerProfile) {
    case "PORT-NJ":
      return "4NJBets";
    case "PORT-PA":
      return "PABets";
    case "PORT-IA":
      return "IA";
    default:
      return "TVG";
  }
};

function handleResponse(response): Action {
  if (!response.data) {
    return response;
  }

  const highlightedRaces = _.get(response, "data.data.lhnFeatureRaces") || [];

  const leftBarData = {
    leftBarRaces: extractRacesFromTracks(
      _.get(response, "data.data.lhnTracks")
    ),
    featuredRaces: mapRaces(highlightedRaces.filter((race) => race.highlighted))
  };

  if (
    leftBarData.leftBarRaces.length === 0 &&
    leftBarData.featuredRaces.length === 0
  ) {
    return successNoResults();
  }

  leftBarData.leftBarRaces = sortByStatus(leftBarData.leftBarRaces);
  leftBarData.featuredRaces = sortFeatureRaces(leftBarData.featuredRaces);

  return successRequest(leftBarData);
}

const getHighlightedData: Function = (wagerProfile: string): Object => ({
  brand: calcBrand(wagerProfile),
  product: "TVG4",
  device: "Desktop"
});

const requestOptions: Function = (
  profile: string = "PORT-Generic",
  // $FlowFixMe
  favoritesTracks: Array = [],
  withGreyhounds: boolean = true,
  // $FlowFixMe
  breedsFilters: Array = [],
  racesCategoryFilters: String
) => {
  const trackFilter =
    racesCategoryFilters === "FAV"
      ? {
          code: favoritesTracks,
          allTrackClasses: withGreyhounds
        }
      : {
          allTrackClasses: withGreyhounds
        };
  const raceFilter =
    breedsFilters.length > 0
      ? {
          status: ["MO", "O", "IC"],
          typeCode: breedsFilters,
          allRaceClasses: withGreyhounds
        }
      : {
          status: ["MO", "O", "IC"],
          allRaceClasses: withGreyhounds
        };
  const highlightedData = getHighlightedData(profile);
  return {
    url: `${ServiceUrl.getServiceUrl("service.graph")}`,
    method: "POST",
    withCredentials: true,
    data: {
      operationName: "getLhnInfo",
      query: lhnQuery,
      variables: {
        wagerProfile: profile,
        trackFilter,
        raceFilter,
        product: highlightedData.product,
        device: highlightedData.device,
        brand: highlightedData.brand,
        breedsFilter: breedsFilters,
        withGreyhounds
      }
    }
  };
};

const makeRequest = (
  dispatch: Function,
  profile: string,
  // $FlowFixMe
  favoritesTracks: Array,
  withGreyhounds: boolean,
  // $FlowFixMe
  breedsFilters: Array,
  racesCategoryFilters: String
) => {
  const options = requestOptions(
    profile,
    favoritesTracks,
    withGreyhounds,
    breedsFilters,
    racesCategoryFilters
  );
  axios(options)
    .then((response) => {
      dispatch(handleResponse(response));
    })
    .catch((err) => {
      dispatch(failRequest(err));
    })
    .then(() => {
      mediator.dispatch("FILTERS_LOADING_FINISHED", {});
    });
};

const startPoller = (
  dispatch: Function,
  profile: string,
  // $FlowFixMe
  favoritesTracks: Array,
  withGreyhounds: boolean,
  // $FlowFixMe
  breedsFilters: Array,
  racesCategoryFilters: String
) =>
  LHNPoller.start(
    () =>
      makeRequest(
        dispatch,
        profile,
        favoritesTracks,
        withGreyhounds,
        breedsFilters,
        racesCategoryFilters
      ),
    LHNInterval
  );
const stopPoller = () => LHNPoller.stop();
const refreshPoller = (
  dispatch: Function,
  profile: string,
  // $FlowFixMe
  favoritesTracks: Array,
  withGreyhounds: boolean,
  // $FlowFixMe
  breedsFilters: Array,
  racesCategoryFilters: String
) =>
  LHNPoller.refresh(
    () =>
      makeRequest(
        dispatch,
        profile,
        favoritesTracks,
        withGreyhounds,
        breedsFilters,
        racesCategoryFilters
      ),
    LHNInterval
  );

export default {
  makeRequest,
  startPoller,
  stopPoller,
  refreshPoller
};
