// @flow
import axios from "axios";
import moment from "moment";
import tvgConf from "@tvg/conf";

type VideoInfo = {
  data: {
    status: "success" | string,
    message?: string,
    filename: string
  }
};

type FileNameRequest = Promise<VideoInfo>;

type ActionReturn = Promise<{ type: string, payload: string }>;
const config = tvgConf().config();

/**
 * Auxiliary to build an url with an object with params
 * @param {object} data Object with the params
 */
export const encodeQueryData = (data: { [string]: string }): string =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");

/**
 * Request for hash
 * @param {string} streamName Stream Value (required to build the hash)
 * @param {number} timestamp Timestamp (unix)
 */
const generateRCNHash = (
  streamName: string,
  timestamp: number
): Promise<{ data: { hash: string } }> =>
  axios.get(`${config.service.rcn}/generateHash`, {
    params: {
      streamname: streamName,
      timestamp
    }
  });

const checkResponseLink = (response) => {
  if (response.data.link) {
    return {
      type: "success",
      payload: response.data.link
    };
  }
  return {
    type: "error",
    payload: "nolink"
  };
};

/**
 * Request the filename for a race replay
 * @param {string} raceDate Race Date
 * @param {string} streamName Stream Name
 * @param {number} raceNumber Race Number
 */
const requestReplayFileName = (
  raceDate: string,
  streamName: string,
  raceNumber: number
): FileNameRequest =>
  axios.get(`${config.service.rcn}/fetchReplayData`, {
    params: {
      date: moment(raceDate).format("YYYYMMDD"),
      track: streamName,
      race: raceNumber
    }
  });

const requestStreamMobile = (
  streamName: string,
  timestamp: number,
  hash: string
): Promise<{ data: { link: string } }> =>
  axios.get("//stream.robertsstream.com/streammobile.php", {
    params: {
      stream: streamName,
      t: timestamp,
      h: hash,
      usr: "",
      referer: "TVG",
      forceformat: "auto",
      output: "json",
      hd: "0"
    }
  });

/**
 * Request HTML5 replay link
 *
 * @param {string} streamName Stream Name
 * @param {number} raceNumber Race Number
 * @param {string} raceDate Race Date
 */
export const GetHTML5Replay = (
  streamName: string,
  raceNumber: number,
  raceDate: string
): ActionReturn => {
  const timestamp = moment().utc().unix();

  return requestReplayFileName(raceDate, streamName, raceNumber)
    .then((videoInfo: VideoInfo) => {
      if (videoInfo.data.status === "success") {
        return generateRCNHash(videoInfo.data.filename, timestamp)
          .then((hash) =>
            axios
              .get(
                "//replays.robertsstream.com/racereplays/replaysmobile.php",
                {
                  params: {
                    race: videoInfo.data.filename,
                    t: timestamp,
                    h: hash.data.hash,
                    usr: "",
                    cust: "TVG",
                    forceformat: "ios",
                    output: "json",
                    hd: "0"
                  }
                }
              )
              .then(checkResponseLink)
              .catch(() => ({
                type: "error",
                payload: "nolink"
              }))
          )
          .catch(() => ({
            type: "error",
            payload: "nohash"
          }));
      }
      return {
        type: "error",
        // Flow is stupid and won't recognize this as a VideoInfo type value
        // $FlowFixMe
        payload: videoInfo.data.message
      };
    })
    .catch(() => ({
      type: "error",
      payload: "noreplay"
    }));
};

/**
 * Request Flash Replay link
 *
 * @param {string} streamName Stream Name
 * @param {number} raceNumber Race Number
 * @param {string} raceDate Race Date
 * @param {number} width Player width (in px)
 * @param {number} height Player height (in px)
 */
export const GetFlashReplay = (
  streamName: string,
  raceNumber: number,
  raceDate: string
): ActionReturn => {
  const timestamp = moment().utc().unix();

  return requestReplayFileName(raceDate, streamName, raceNumber)
    .then((videoInfo: VideoInfo) => {
      if (videoInfo.data.status === "success") {
        return generateRCNHash(videoInfo.data.filename, timestamp)
          .then((hash) => {
            // replay exist
            const link = `http://replays.robertsstream.com/racereplays/replaysflash.php?${encodeQueryData(
              {
                stream: videoInfo.data.filename,
                t: timestamp.toString(),
                h: hash.data.hash,
                cust: "TVG",
                width: "100%",
                height: "100%"
              }
            )}`;
            return {
              type: "success",
              payload: link
            };
          })
          .catch(() => ({
            type: "error",
            payload: "nohash"
          }));
      }
      return {
        type: "error",
        // Flow is stupid and won't recognize this as a VideoInfo type value
        // $FlowFixMe
        payload: videoInfo.data.message
      };
    })
    .catch(() => ({
      type: "error",
      payload: "noreplay"
    }));
};

/**
 * Request Flash Stream link
 *
 * @param {string} streamName Stream Name
 * @param {number} width Player width (in px)
 * @param {number} height Player height (in px)
 */
export const GetFlashStream = (
  streamName: string,
  width: number,
  height: number,
  isHD: boolean,
  timestamp: number = moment().utc().unix()
): ActionReturn =>
  generateRCNHash(streamName, timestamp)
    .then((response) =>
      requestStreamMobile(streamName, timestamp, response.data.hash)
        .then((videoResponse) => {
          if (videoResponse.data.link) {
            const link = `//stream.robertsstream.com/streamlive.php?${encodeQueryData(
              {
                stream: streamName,
                t: timestamp.toString(),
                h: response.data.hash,
                referer: "TVG",
                hd: isHD ? "1" : "0",
                width: "100%",
                height: "100%"
              }
            )}`;
            return {
              type: "success",
              payload: link
            };
          }
          return {
            type: "error",
            payload: "nolink"
          };
        })
        .catch(() => ({
          type: "error",
          payload: "rcnerror"
        }))
    )
    .catch(() => ({
      type: "error",
      payload: "hasherror"
    }));

/**
 * Request HTML5 Stream Link
 * @param {string} streamName Stream Name
 */
export const GetHTML5Stream = (
  streamName: string,
  timestamp: number = moment().utc().unix()
): ActionReturn =>
  generateRCNHash(streamName, timestamp)
    .then((response) =>
      requestStreamMobile(streamName, timestamp, response.data.hash)
        .then(checkResponseLink)
        .catch(() => ({
          type: "error",
          payload: "rcnerror"
        }))
    )
    .catch(() => ({
      type: "error",
      payload: "hasherror"
    }));
