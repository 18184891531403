import styled from "styled-components/native";
import { margin, compose, variant } from "styled-system";
import { rgba } from "polished";
import { TouchableHighlight, View, Text } from "react-native";
import { RefAttributes } from "react";
import { Icon } from "../icon";
import {
  StatesProps,
  TransientStatesProps,
  StyledStatesProps,
  Theme
} from "./types";

export const colorStates = {
  prop: "state",
  variants: {
    normal: {
      backgroundColor: "blue_accent.000",
      color: "grey.800",
      borderColor: "blue.100"
    },
    hovered: {
      backgroundColor: "blue_accent.100",
      color: "blue_accent.600",
      borderColor: "blue_accent.500"
    },
    pressed: {
      backgroundColor: "blue_accent.200",
      color: "blue_accent.600",
      borderColor: "blue_accent.500",
      boxShadow: "none"
    }
  }
};

export const stylesColorStates = compose(variant(colorStates));

const getState = (
  isHovered: boolean,
  isPressed: boolean,
  isSelected: boolean
) => {
  if (isPressed) return "pressed";
  if (isHovered || isSelected) return "hovered";
  return "normal";
};

const getColorIconCircle = (
  isHovered: boolean,
  isPressed: boolean,
  isSelected: boolean,
  theme: Theme
) => {
  if (isPressed) return theme?.colors.blue_accent[300];
  if (isHovered || isSelected) return theme?.colors.blue_accent[200];
  return theme?.colors.blue_accent[100];
};

const getColorIconLine = (
  isHovered: boolean,
  isPressed: boolean,
  isSelected: boolean,
  theme: Theme
) => {
  if (isPressed) return theme?.colors.blue_accent[600];
  if (isHovered || isSelected) return theme?.colors.blue_accent[600];
  return theme?.colors.blue[400];
};

export const StyledViewTagContainer = styled(View)`
  position: absolute;
  top: -9px;
  right: -9px;
`;

export const StyledTouchableHighlight = styled(
  TouchableHighlight
).attrs<StatesProps>(({ isHovered, isPressed, isSelected }) => ({
  state: getState(isHovered, isPressed, isSelected)
}))<StyledStatesProps & RefAttributes<TouchableHighlight>>`
  ${margin}
`;

export const StyledViewContainer = styled(View).attrs<StatesProps>(
  ({ isHovered, isPressed, isSelected }) => ({
    state: getState(isHovered, isPressed, isSelected)
  })
)<StyledStatesProps>`
  ${margin};
  border-width: 1px;
  ${({ theme, isHovered, isSelected }) =>
    (isHovered || isSelected
      ? "box-shadow: 0 2px 4px "
      : "box-shadow: 0 1px 3px ") +
    rgba(theme.colors.blue[900], isHovered || isSelected ? 0.18 : 0.12)};
  width: 104px;
  border-radius: ${({ theme }) => theme.radii.s};
  padding: ${({ theme }) => theme.space["space-4"]};
  align-items: center;
  ${stylesColorStates}
`;

export const StyledViewIconCircle = styled(View)<StatesProps>`
  background-color: ${({ theme, isHovered, isPressed, isSelected }) =>
    getColorIconCircle(isHovered, isPressed, isSelected, theme)};
  width: 40px;
  height: 40px;
  border-radius: 99px;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.space["space-4"]};
`;

// transient props here avoid error on svg because the camel case prop names
// https://styled-components.com/docs/api (Styled System 5.1)
export const StyledIcon = styled(Icon).attrs<TransientStatesProps>(
  ({ theme, $isHovered, $isPressed, $isSelected }) => ({
    lineColor: getColorIconLine($isHovered, $isPressed, $isSelected, theme),
    backgroundColor: "transparent"
  })
)<TransientStatesProps>``;

export const StyledTextTitle = styled(Text).attrs<StatesProps>(
  ({ isHovered, isPressed, isSelected }) => ({
    state: getState(isHovered, isPressed, isSelected)
  })
)`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${({ theme }) => theme.fontSizes.s};
  margin-bottom: ${({ theme }) => theme.space["space-2"]};
  ${stylesColorStates}
`;

export const StyledTextDescription = styled(Text).attrs<StatesProps>(
  ({ isHovered, isPressed, isSelected }) => ({
    state: getState(isHovered, isPressed, isSelected)
  })
)<StatesProps>`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.grey[800]};
  ${stylesColorStates}
`;
