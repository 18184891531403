import tvgConf from "@tvg/conf";
import { get } from "lodash";
import getProtocol from "../protocolSetter";
import requester from "../requester";

const pesService: string = "service.pes";

const fetchQuickLinks = () =>
  requester()({
    method: "GET",
    url: `${getProtocol()}${tvgConf().config(pesService)}/homepage/quicklinks`,
    headers: { "x-tvg-context": tvgConf().context(tvgConf().product, true) },
    withCredentials: false
  });

const fetchHomepageConfiguration = (withoutTimeout: boolean = false) => {
  const timeout = withoutTimeout ? {} : { timeout: 3000 };
  return requester()({
    method: "GET",
    url: `${getProtocol()}${tvgConf().config(
      pesService
    )}/homepage/configurations`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: false,
    ...timeout
  }).then((res) => get(res, "data[0].components", null));
};

const PES_SERVICE = {
  fetchQuickLinks,
  fetchHomepageConfiguration
};

export default PES_SERVICE;
