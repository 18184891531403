// @flow
import React from "react";
import * as mediator from "@tvg/mediator-classic/src";
// $FlowFixMe
import ExclamationIcon from "@tvg/ui-bootstrap/assets/svg/exclamation.svg";
import style from "./style.css";

type Props = {
  label?: string,
  labelWithFilters?: string,
  doRefresh?: Function,
  showRefresh?: boolean,
  filtersApplied: boolean
};

export const refresh = () => {
  // refresh action
};

const clearFilters = () => {
  mediator.dispatch("RACE_FILTERS:CLEAR", {});
};

const NoRaces = (props: Props) => (
  <div className={style.container}>
    <ExclamationIcon className={style.icon} />

    <span className={style.label}>
      {!props.filtersApplied ? props.label : props.labelWithFilters}
    </span>

    {props.filtersApplied && (
      <button className={style.button} onClick={clearFilters}>
        Clear Filters
      </button>
    )}

    {props.showRefresh && !props.filtersApplied && (
      <button
        className={style.button}
        onClick={() =>
          // $FlowFixMe
          props.doRefresh()
        }
      >
        refresh
      </button>
    )}
  </div>
);

NoRaces.defaultProps = {
  label: "There are no races available at the moment",
  labelWithFilters: "There are no races available with filters applied",
  doRefresh: refresh,
  showRefresh: false
};

export default NoRaces;
