let setIntervalId;

const networkError = (result) => {
  let error = false;
  try {
    // networkStatus error is the number 8. See:
    // https://github.com/apollostack/apollo-client/blob/master/src/queries/store.ts
    error = result.data.networkStatus === 8;
  } catch (e) {
    error = false;
  }

  return error;
};

const queryVariables = (profile) => ({
  wagerProfile: profile || "PORT-Generic",
  sortByName: { byName: "ASC" },
  sortByTime: { byMTP: "ASC" },
  sortByPostTime: { byPostTime: "ASC" },
  allTrackClasses: { allTrackClasses: true },
  isGreyhound: { isGreyhound: true },
  filterMTP: {
    allRaceClasses: true,
    startIn: 60,
    status: ["MO", "O", "IC", "SK"]
  },
  statusAndGreyhounds: { status: ["MO", "O", "IC", "SK"], isGreyhound: true },
  page: { results: 1, current: 0 }
});

export default {
  options: (props) => ({
    variables: queryVariables(props.profile),
    pollInterval: 15000,
    ssr: false
  }),
  props: (result) => {
    if (networkError(result) && !setIntervalId) {
      setIntervalId = setInterval(() => {
        result.data.refetch();
      }, 15000);
    } else if (!networkError(result) && setIntervalId) {
      clearInterval(setIntervalId);
      setIntervalId = null;
    }
    return {
      apollo: result.data
    };
  }
};
