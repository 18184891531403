/**
 * Dropdown for video component
 */

// @flow
import React, { Component } from "react";
import type { Element } from "react";
import _ from "lodash";
import classNames from "classnames";

import Item from "../Dropdown/Item/index";
import ContainerItem from "../Dropdown/containerItem/index";
import List from "../Dropdown/List/index";
import Label from "../Dropdown/Label/index";

import TvgLogoWhite from "../../assets/images/tvg-logo-white.svg";
import Tvg2LogoWhite from "../../assets/images/tvg2-logo-white.svg";
import style from "./style.css";

type Track = {
  name: string,
  trackAbbr: string,
  perfAbbr: string,
  nextRace: number,
  streamName: string,
  timeLabel: string,
  status: string,
  channel: string,
  type: number,
  isHD: boolean
};

type TrackCallback = (a: ?Track) => Track;

/* eslint-disable react/no-unused-prop-types */

type TrackList = {
  tvgChannels: Array<Track>,
  favoriteTracks: Array<Track>,
  featureTracks: Array<Track>,
  otherTracks: Array<Track>
};

/* eslint-enable */

type Props = {
  /**
   * Dropdown track list
   */
  trackList: TrackList,
  /**
   * bet callBack
   */
  selectTrackCallBack: TrackCallback,
  /**
   * Custom class
   */
  customClass?: string
};

type State = {
  trackList: TrackList
};

/**
 * Handle function callback
 */
export const handleCallback = (
  event: ?Event,
  callback?: TrackCallback,
  selectedTrack?: Track
): void => {
  if (event) {
    event.preventDefault();
  }
  if (callback) {
    callback(selectedTrack);
  }
};

/**
 * show tvg channel icon
 */
const renderChannels = (channel: ?string) => {
  switch (channel) {
    case "1":
      return <TvgLogoWhite width="40" />;
    case "2":
      return <Tvg2LogoWhite width="50" />;
    default:
      return null;
  }
};

/**
 * show tvg channel icon
 */
const renderLabel = (length, label) => {
  if (length > 0) {
    return <Label customClass={style.dropDownLabel} label={label} />;
  }
  return [];
};

/**
 * Dropdown for Video Wrapper Component
 *
 * This component will build the tvg video dropdown
 *
 */
export default class VideoDropdown extends Component {
  /** ---- Component Types ---- */

  static defaultProps = {
    trackList: {
      tvgChannels: [],
      favoriteTracks: [],
      featureTracks: [],
      otherTracks: []
    },
    customClass: ""
  };

  constructor(props: Props) {
    super(props);
    _.bindAll(this, ["selectVideoRace"]);

    this.state = {
      trackList: this.props.trackList
    };
  }

  state: State;

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      trackList: nextProps.trackList
    });
  }

  props: Props;

  /**
   * Select Video Race
   */
  selectVideoRace(event: ?Event, selectedTrack: Track): void {
    handleCallback(event, this.props.selectTrackCallBack, selectedTrack);
  }

  /**
   * Render TVG channel lines
   */
  renderTVGChannelLi(channels: Array<Track>): Element<*>[] {
    if (channels.length === 0) {
      return [];
    }
    return channels.map((track) => (
      <ContainerItem
        key={`${track.trackAbbr}${track.channel}`}
        customClass={style.dropDownChannelSection}
        onClickFn={(event) => this.selectVideoRace(event, track)}
      >
        Watch the {renderChannels(track.channel)} channel - live
      </ContainerItem>
    ));
  }

  /**
   * Render TrackList
   */
  renderTrackList(tracks: Array<Track>): Element<*>[] {
    if (tracks.length === 0) {
      return [];
    }
    return tracks.map((track) => (
      <Item
        customClass={style.dropDownItem}
        name={track.name}
        harness={track.type === 2}
        number={track.nextRace}
        timeLabel={track.timeLabel}
        key={`${track.trackAbbr}${track.nextRace}${track.timeLabel}`}
        onClickFn={(event) => this.selectVideoRace(event, track)}
      />
    ));
  }

  /**
   * This will render the dropdown elements
   */
  render(): Element<any> {
    const {
      tvgChannels,
      favoriteTracks,
      featureTracks,
      otherTracks
    } = this.state.trackList;
    return (
      <List
        closeDropdownCallBack={() => { }}
        customClass={classNames(
          style.dropDownComponent,
          this.props.customClass
        )}
      >
        {renderLabel(tvgChannels.length, "TV Channels")}
        {this.renderTVGChannelLi(tvgChannels)}
        {renderLabel(favoriteTracks.length, "Favorite Tracks")}
        {this.renderTrackList(favoriteTracks)}
        {renderLabel(featureTracks.length, "Popular Tracks")}
        {this.renderTrackList(featureTracks)}
        {renderLabel(otherTracks.length, "All Tracks")}
        {this.renderTrackList(otherTracks)}
      </List>
    );
  }
}
