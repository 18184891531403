// @flow
import type { QuickLink } from "@tvg/types/Links";

export type StoreQuickLinkAction = {
  type: "STORE_QUICK_LINKS",
  payload: QuickLink[]
};

export type StoreQuickLinkDataAction = {
  type: "STORE_QUICK_LINKS_DATA",
  payload: QuickLink[]
};

export type Actions = StoreQuickLinkAction | StoreQuickLinkDataAction;

const storeQuickLinks = (links: QuickLink[]): StoreQuickLinkAction => ({
  type: "STORE_QUICK_LINKS",
  payload: links
});

const storeQuickLinksData = (data: QuickLink[]): StoreQuickLinkDataAction => ({
  type: "STORE_QUICK_LINKS_DATA",
  payload: data
});

export { storeQuickLinks, storeQuickLinksData };
