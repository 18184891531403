module.exports = {
  getContextStyle: (context, styleDesktop, styleMobile) => {
    let style;

    if (context === "lhn") {
      style = styleDesktop;
    }

    if (context === "mhdr") {
      style = styleMobile;
    }

    return style;
  }
};
