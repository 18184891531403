// @flow
import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import type { RouterHistory, Location } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import * as mediator from "@tvg/mediator-classic/src";
import QuickLinksEvents from "@tvg/gtm/src/modules/QuickLinks";
import { graphql } from "@apollo/client/react/hoc";

import PropTypes from "prop-types";
import classNames from "classnames";
import tvgConf from "@tvg/conf";
import QuickLinks from "@tvg/quick-links";

import {
  successCapiRequest,
  failCapiRequest,
  successCmsRequest,
  failCmsRequest,
  openSideMenu,
  changeProfile,
  updateFavoriteTracks,
  updateFilters
} from "../../actions/LHNRequestActions";

import DropSideComponent from "../../components/DropSide";
import style from "./style.css";
import LeftBarFeatureRacesComp from "../../components/LeftBarFeatureRaces/index";
import TabsSystem from "../../components/TabsSystem";
import lhnGraphService from "../../services/RacesService/RacesService";
import LeftBarNextRaces from "../../components/LeftBarNextRaces";
import LeftBarGreyHoundsRaces from "../../components/LeftBarGreyHoundsRaces";
import VideoTabListComp from "../../components/VideoTabList";
import getTVGChannel from "../../factories/getTVGChannel";
import extractFeatureToggleValue from "../../factories/getPreferences";
import videoListInfoService from "../../services/VideoListInfoService/VideoListInfoService";
import ServiceUrl from "../../factories/serviceUrl";
import { type Track, type VideoTrackList } from "../../../../types.flow";
// $FlowFixMe
import LhnGraphQuery from "./LhnGraphQuery";
import QueryOptions from "./options.graph";
import validateGreyhoundProfile from "../../factories/validateGreyHounds";

const NEXT_RACES_TAB_LABEL = "Next Races";
const LIVE_VIDEO_TAB_LABEL = "Live Video";

type Props = {
  mode: string,
  dispatch: Function,
  leftBarRacesLoading: boolean,
  openSideMenu: boolean,
  profile: string,
  featureToggles: Array<Object>,
  apollo: {
    refetch: Function,
    tracks: Array<Track>,
    nextRacesGreyhounds: Array<Track>,
    mtpGlobalUpdate: Array<Track>
  },
  lhnRaces: Object,
  favoritesTracks: Array<String>,
  greyhoundsProfiles: Array<String>,
  useQuickLinksExpanded: boolean,
  location: Location,
  history: RouterHistory
};

type State = {
  showLhnOnHamburguer: boolean,
  videoTrackList: VideoTrackList,
  currentPath: string
};

let profile = "PORT-Generic";

const getFeatures = (dispatch) => {
  tvgConf()
    .getFeatures()
    .then((response) => {
      dispatch(successCapiRequest(response));
    })
    .catch((err) => {
      dispatch(failCapiRequest(err));
    });
};

const getMessages = (dispatch) => {
  tvgConf()
    .getMessages(["Global"])
    .then((response) => {
      dispatch(successCmsRequest(response));
    })
    .catch((err) => {
      dispatch(failCmsRequest(err));
    });
};

export class LHN extends Component<Props, State> {
  static defaultProps = {
    featureToggles: [],
    useQuickLinksExpanded: false
  };

  constructor(props: Props): void {
    super(props);
    _.bindAll(this, [
      "menuSubscription",
      "subscribeNavigation",
      "updateSession",
      "getTvgChannels",
      "getHideVideoTabToggle",
      "updateFavorites",
      "withGreyHound",
      "updateQuery",
      "isGreyHoundAvailable",
      "loadFavorites",
      "setFiltersState",
      "processFilters"
    ]);
    getMessages(this.props.dispatch);
    getFeatures(this.props.dispatch);
  }

  state: State = {
    showLhnOnHamburguer: false,
    videoTrackList: {
      tvgChannels: [],
      favoritesTracks: [],
      featureTracks: [],
      otherTracks: [],
      favoriteTracks: []
    },
    currentPath: "/"
  };

  componentWillMount(): void {
    this.setState({
      videoTrackList: videoListInfoService.processGraphTrackList(
        [],
        this.getTvgChannels()
      )
    });
  }

  componentDidMount(): void {
    mediator.subscribeWithPast(
      "TVG_LOGIN:USER_SESSION_UPDATE",
      this.updateSession
    );
    QuickLinksEvents();
    mediator.subscribe("UPDATE_ROUTER", (data) => {
      this.subscribeNavigation();
      this.setState({
        currentPath: _.get(data, "payload.route", window.location.pathname)
      });

      const pathWithParameters = window.location.href.replace(
        window.location.origin,
        ""
      );

      if (this.props.location.pathname !== pathWithParameters) {
        this.props.history.replace(pathWithParameters);
      }
    });
    mediator.subscribeWithPast("LOAD_FAVORITE_TRACKS", this.loadFavorites);
    mediator.subscribeWithPast("LOAD_FILTERS:UPDATE", this.processFilters);
    mediator.subscribeWithPast("LOAD_PREFERENCES", this.updateQuery);
    mediator.subscribe("OPEN_SIDE_MENU", this.menuSubscription);
    mediator.subscribe("NEW_FAVORITE_TRACKS", this.updateFavorites);
    mediator.subscribe("RACE_FILTERS:UPDATE", this.updateQuery);
  }

  componentWillReceiveProps(newProps: Props): void {
    this.setState({
      videoTrackList: videoListInfoService.processGraphTrackList(
        this.props.apollo.tracks,
        this.getTvgChannels()
      )
    });
    if (profile !== newProps.profile) {
      profile = newProps.profile;
      this.props.apollo.refetch();
    }
  }

  componentWillUnmount(): void {
    lhnGraphService.stopPoller();
    mediator.unsubscribe("OPEN_SIDE_MENU", this.menuSubscription);
    mediator.unsubscribe("UPDATE_ROUTER", this.subscribeNavigation);
    mediator.unsubscribe("TVG_LOGIN:USER_SESSION_UPDATE", this.updateSession);
    mediator.unsubscribe("LOAD_PREFERENCES", this.updateQuery);
    mediator.unsubscribe("LOAD_FAVORITE_TRACKS", this.loadFavorites);
    mediator.unsubscribe("LOAD_FILTERS:UPDATE", this.updateQuery);
    mediator.unsubscribe("NEW_FAVORITE_TRACKS", this.updateFavorites);
    mediator.unsubscribe("RACE_FILTERS:UPDATE", this.updateQuery);
  }

  // $FlowFixMe I don't understand this - MP
  setFiltersState(group: string, breed: Array) {
    this.filtersApplied = group && breed && (group !== "ALL" || !!breed.length);
  }

  getHideVideoTabToggle(): boolean {
    const hideVideo = _.find(
      this.props.featureToggles,
      (toggle) => toggle.name === "hideVideoLhn"
    );
    return hideVideo ? hideVideo.enabled : false;
  }

  getTvgChannels(): Array<Track> {
    const onTvgToggleActive: boolean = extractFeatureToggleValue(
      this.props.featureToggles,
      "leftBarOnTvgVideo"
    );
    const onTvg2ToggleActive: boolean = extractFeatureToggleValue(
      this.props.featureToggles,
      "leftBarOnTvg2Video"
    );

    const returnValue = [];

    if (onTvgToggleActive) {
      returnValue.push(getTVGChannel(1));
    }

    if (onTvg2ToggleActive) {
      returnValue.push(getTVGChannel(2));
    }

    return returnValue;
  }

  isGreyHoundAvailable() {
    return validateGreyhoundProfile(
      this.props.greyhoundsProfiles,
      this.props.profile
    );
  }

  withGreyHound(): boolean {
    return !!_.find(
      this.props.featureToggles,
      (toggle) => toggle.name === "coMingleGreyhounds"
    );
  }

  props: Props;

  filtersApplied: boolean;

  subscribeNavigation(): void {
    const { mode } = this.props;
    const path = window.location.pathname;
    const pathWithParameters = window.location.href.replace(
      window.location.origin,
      ""
    );

    let shouldRefresh = true;
    if (_.find(["standard", "greyhounds"], (value) => value === mode)) {
      shouldRefresh = false;
      if (
        _.find(
          [
            "registration",
            "blocked-country",
            "reset-credentials",
            "credentials-reset",
            "forgot-credentials",
            "responsible-gaming"
          ],
          (value) => path.indexOf(value) > -1
        )
      ) {
        shouldRefresh = true;
      }
      if (
        mode === "standard" &&
        path.indexOf("greyhounds") > -1 &&
        !this.withGreyHound()
      ) {
        shouldRefresh = true;
      }
      if (
        mode === "greyhounds" &&
        path.indexOf("greyhounds") < 0 &&
        !this.withGreyHound()
      ) {
        shouldRefresh = true;
      }
    }
    if (shouldRefresh && _.get(this, "url") !== pathWithParameters) {
      this.props.history.replace(pathWithParameters);
    }
  }

  processFilters(data: ?Object) {
    if (!data) {
      return;
    }
    if (typeof data === "string") {
      this.props.dispatch(updateFilters(data));
      return;
    }
    if (_.get(data, "raceFilters")) {
      this.props.dispatch(updateFilters(data.raceFilters));
    }
  }

  updateQuery(data: ?Object) {
    // make sure that none of the poller are running
    lhnGraphService.stopPoller();

    this.processFilters(data);

    setTimeout(() => {
      let filters = {};
      if (_.get(this.props, "isLogged") && this.isGreyHoundAvailable()) {
        filters = _.get(this.props, "filters");
      }
      this.setFiltersState(_.get(filters, "group"), _.get(filters, "breed"));

      lhnGraphService.startPoller(
        this.props.dispatch,
        this.props.profile,
        this.props.favoritesTracks,
        this.withGreyHound(),
        _.get(filters, "breed"),
        _.get(filters, "group")
      );
    }, 50);
  }

  updateSession(data: { profile: string, logged: boolean }) {
    let user = _.get(data, "user") || {};
    user = { ...user, isLogged: _.get(data, "logged") };

    this.props.dispatch(changeProfile(user));

    if (!user.isLogged) {
      // $FlowFixMe
      this.props.dispatch(updateFavoriteTracks([]));
      this.updateQuery({});
    }
  }

  updateFavorites(data: Object) {
    this.loadFavorites(data);
  }

  loadFavorites(data: Object) {
    let favorites = _.get(data, "favoriteTracks") || [];
    if (typeof favorites === "string") {
      favorites = favorites.split(",");
    }
    // $FlowFixMe
    this.props.dispatch(updateFavoriteTracks(favorites));
    this.updateQuery(null);
  }

  menuSubscription(data: { open: boolean }) {
    this.props.dispatch(openSideMenu(data.open));
  }

  renderFeatureRaces = () =>
    extractFeatureToggleValue(this.props.featureToggles, "LHNfeatureRaces") ? (
      <LeftBarFeatureRacesComp racesMTP={this.props.apollo.mtpGlobalUpdate} />
    ) : null;

  renderLinks = () =>
    (this.state.currentPath !== "/" &&
      this.state.currentPath !== "/home" &&
      this.state.currentPath !== "/login") ||
    (this.props.featureToggles.length &&
      !extractFeatureToggleValue(
        this.props.featureToggles,
        "showQuickLinksExpanded"
      )) ? (
      <div className={style.quickLinksContainer}>
        <QuickLinks device="desktop" />
      </div>
    ) : null;

  render(): React$Element<any> {
    const { leftBarRacesLoading, mode } = this.props;
    const nj = ServiceUrl.getBrand() === "4njbets" ? style.nj : "";
    const lhnStyle = this.props.openSideMenu
      ? classNames(
          style.leftNavBar,
          style.leftNavBarHide,
          style.showLhnOnHamburguer,
          nj
        )
      : classNames(style.leftNavBar, style.leftNavBarHide, nj);

    if (mode === "hide") {
      return <div />;
    }

    const nextRacesComp =
      mode === "greyhounds" && !this.withGreyHound() ? (
        <LeftBarGreyHoundsRaces
          tracks={this.props.apollo.nextRacesGreyhounds || []}
        />
      ) : (
        <LeftBarNextRaces
          racesMTP={this.props.apollo.mtpGlobalUpdate}
          filtersApplied={this.filtersApplied}
        />
      );

    const showLhnHeader = extractFeatureToggleValue(
      this.props.featureToggles,
      "showLhnHeader"
    );

    // @TODO Tabs might later migrate to its own component
    return (
      <div className={`${lhnStyle}`}>
        {this.renderLinks()}
        <DropSideComponent videoTrackList={this.state.videoTrackList} />
        <nav>
          <div className={`${style.leftNavContent}`}>
            <TabsSystem
              loading={leftBarRacesLoading}
              headerColumns={[NEXT_RACES_TAB_LABEL, LIVE_VIDEO_TAB_LABEL]}
              withFilters={this.isGreyHoundAvailable()}
              showLhnHeader={showLhnHeader}
              tabs={[
                nextRacesComp,
                <VideoTabListComp
                  videoWrapperData={this.state.videoTrackList}
                  disableTab={this.getHideVideoTabToggle()}
                  featureToggles={this.props.featureToggles}
                />
              ]}
            />
          </div>
          {this.renderFeatureRaces()}
        </nav>
      </div>
    );
  }
}

LHN.contextTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  router: PropTypes.object
};

export default compose(
  connect((store, props) => ({
    mode: props.mode,
    profile: store.lhnProfile.profile,
    isLogged: store.lhnProfile.isLogged,
    favoritesTracks: store.lhnProfile.favoritesTracks,
    filters: store.lhnProfile.filters,
    lhnRaces: store.lhnRaces,
    leftBarRacesLoading: store.lhnRaces.leftBarRacesLoading,
    openSideMenu: store.lhnRaces.openSideMenu,
    featureToggles: store.lhnFeatureToggles.featureToggles,
    greyhoundsProfiles: store.lhnProfile.greyhoundsProfiles
  })),
  graphql(LhnGraphQuery, QueryOptions)
)(withRouter(LHN));
