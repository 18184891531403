// @flow
import React from "react";
import { dispatch } from "@tvg/mediator-classic/src";
import NoVideoIcon from "../../../assets/svg/mtp-wvideo.svg";
import style from "./style.css";

type Props = {
  /** Title Label */
  title: string
};

/**
 * Video not Availeble Component
 * @param {Props} props
 */
const Login = (
  props: Props // Button dont work with flex
) => (
  <a
    tabIndex={0}
    role="button"
    className={style.LoginContainer}
    onClick={() => {
      dispatch("TVG_LOGIN:OPEN_LOGIN_MODAL", {});
    }}
  >
    <div className={style.LoginTitle}>{props.title}</div>
    <NoVideoIcon />
    <div className={style.LoginDescription}>
      Please <span className={style.LoginLabel}>Log in</span> to watch{" "}
      {props.title.toLowerCase()}
    </div>
  </a>
);

export default Login;
