export default function reducer(
  state = {
    featureToggles: [],
    completeToggleRequest: false
  },
  action
) {
  switch (action.type) {
    case "LHN_CAPI_REQUEST_SUCCESS": {
      return {
        ...state,
        featureToggles: action.payload.featureToggles,
        completeToggleRequest: true
      };
    }
    case "LHN_CAPI_REQUEST_FAIL": {
      return {
        ...state,
        completeToggleRequest: true
      };
    }
    default: {
      return state;
    }
  }
}
