// @flow
import React, { Component } from "react";
import styleDesktop from "./style.desktop.css";
import styleMobile from "./style.mobile.css";
import { options } from "../../models/filterBreedOptions";
import { getContextStyle } from "../../factories/styleContext";
import RaceFilterTittle from "../RaceFilterTittle";
import RaceFilterOptionsGroup from "../RaceFilterOptionsGroup";

type Props = {
  context: string,
  values: Array<string>,
  updateBreed: (par: string) => void
};

class RaceFilterBreed extends Component {
  constructor(props: Props) {
    super(props);

    this.style = getContextStyle(this.props.context, styleDesktop, styleMobile);
  }

  props: Props;

  render() {
    return (
      <div className={this.style.container}>
        <RaceFilterTittle context={this.props.context} title="Breed" />
        <RaceFilterOptionsGroup
          context={this.props.context}
          values={this.props.values}
          toggleOption={this.props.updateBreed}
          options={options}
        />
      </div>
    );
  }
}

export default RaceFilterBreed;
