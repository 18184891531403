// @flow
import React, { Component } from "react";
import classnames from "classnames";
import { getContextStyle } from "../../factories/styleContext";
import styleDesktop from "./style.desktop.css";
import styleMobile from "./style.mobile.css";

type Props = {
  context: string,
  title: string
};

class RaceFilterTittle extends Component {
  constructor(props: Props) {
    super(props);

    this.style = getContextStyle(this.props.context, styleDesktop, styleMobile);
  }

  props: Props;

  render() {
    const tittleClasses = classnames({
      [this.style.title]: true,
      [this.style.separator]: this.props.context === "lhn"
    });

    return <h2 className={tittleClasses}>{`${this.props.title}`}</h2>;
  }
}

export default RaceFilterTittle;
