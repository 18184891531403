// TODO: check Referer and Hd videos

// @flow
import React, { Component } from "react";
import classnames from "classnames";
import VideoIcon from "../../assets/svg/mtp-wvideo.svg";
import Loading from "../Loading/index.css";
import PopoutVideo from "../../assets/svg/popout-video.svg";
import NoVideo from "./NoVideo";
import Login from "./Login";

/**
 * Video Actions
 */
import {
  GetHTML5Replay,
  GetHTML5Stream,
  GetFlashStream,
  GetFlashReplay
} from "./actions";

import style from "./style.css";

// @FIXME https://github.com/facebook/flow/issues/1660
type Props = {
  /**
   * True if Video is a Replay
   */
  isReplay: boolean,
  /**
   * True if the desired player as to use flash (no mobile)
   */
  isFlash: boolean,
  /**
   * Message error to display on replay error
   */
  replayErrorMessage: string,
  /**
   * Message error to display on streaming error
   */
  liveErrorMessage: string,
  /**
   * Stream name
   */
  streamName: string,
  /**
   * Race Number, only used if video is a replay
   */
  raceNumber: number,
  /**
   * Race Date, only use if video is a replay
   */
  raceDate: string,
  /**
   * Video class overRight
   */
  videoClass?: string,
  /**
   *  Video hd video
   */
  isHD: boolean,
  /*
   * True if User is logged in. It false video show a button to open the Login Modal
   */
  userLoggedIn: boolean,
  /*
   * Popout video player
   */
  onPopOut: () => mixed
};

type State = {
  isLoading: boolean,
  link: ?string,
  error: ?string
};

/**
 * Return the size of the video player to 16:9
 * @param {number} width Video Parent width
 * @param {number} height Video Parant height
 */
const calculate16By9Sizes = (width: number, height: number) => {
  const height16by9 = Math.round(width / (16 / 9));
  if (height < height16by9) {
    return { width: Math.round(height * (16 / 9)), height };
  }

  return { width, height: height16by9 };
};

/**
 * TVG Video Component
 *
 * This component allow to play rcn streaming and replays.
 *
 * The requests for hash, replay filename and video urls are made by
 * the component and managed by is own state.
 */
export default class Video extends Component {
  /** ---- Component Types ---- */

  static defaultProps = {
    replayErrorMessage: "Replay for this race is not available",
    liveErrorMessage: "Live streaming for this race is not available",
    isReplay: false,
    isFlash: true,
    streamName: "",
    raceDate: "",
    raceNumber: 0,
    videoClass: "",
    isHD: false,
    userLoggedIn: false,
    onPopOut: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      link: null,
      error: null
    };

    this.renderPopout = this.renderPopout.bind(this);
    this.handlePlayVideo = this.handlePlayVideo.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setVideo = this.setVideo.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  state: State;
  /**
   * The component reset his state if the streamName or the raceNumber change.
   * Otherwise the player continue to play de current video
   *
   * @param {Props} nextProps Next Props
   */
  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.streamName !== this.props.streamName ||
      nextProps.raceNumber !== this.props.raceNumber
    ) {
      this.resetState();
    }
  }

  setLoading: Function;
  /**
   * Update the component state with the info that is loading (fetching video)
   */
  setLoading(): void {
    this.setState({
      isLoading: true
    });
  }

  setVideo: Function;
  /**
   * Update the component by the given argument
   */
  setVideo(response: { type: string, payload: string }): void {
    if (response.type === "success") {
      this.setState({
        isLoading: false,
        link: response.payload,
        error: null
      });
    } else {
      this.setState({
        isLoading: false,
        error: response.payload,
        link: null
      });
    }
  }

  resetState: Function;
  /**
   * Reset the component state for initial Values
   *
   * loading: false, link: null, error: null
   */
  resetState(): void {
    this.setState({
      isLoading: false,
      link: null,
      error: null
    });
  }

  props: Props;

  videoWrapper: {
    clientWidth: number,
    clientHeight: number
  };
  handlePlayVideo: Function;
  /** Handle video request action */
  handlePlayVideo(): void {
    // Is Replay
    if (!this.props.streamName) {
      return; //
    }

    if (this.props.isReplay && this.props.raceNumber && this.props.raceDate) {
      if (this.props.isFlash) {
        GetFlashReplay(
          this.props.streamName,
          this.props.raceNumber,
          this.props.raceDate
        ).then(this.setVideo);
        return;
      }
      // Not Flash
      GetHTML5Replay(
        this.props.streamName,
        this.props.raceNumber,
        this.props.raceDate
      ).then(this.setVideo);

      return;
    }

    if (this.props.isFlash) {
      const { width, height } = calculate16By9Sizes(
        this.videoWrapper.clientWidth,
        this.videoWrapper.clientHeight
      );
      GetFlashStream(
        this.props.streamName,
        width,
        height,
        this.props.isHD
      ).then(this.setVideo);

      return;
    }

    GetHTML5Stream(this.props.streamName).then(this.setVideo);
  }
  /**
   * Return the video element. If it's flash return an iframe otherwise return a html5 video
   */
  renderVideo() {
    if (this.props.isFlash) {
      return (
        <iframe
          title={this.state.link}
          src={this.state.link}
          height="100%"
          width="100%"
          frameBorder="0"
          scrolling="no"
        />
      );
    }

    return (
      <video
        className={style.VideoPlayer}
        src={this.state.link}
        autoPlay
        height="100%"
        width="100%"
        placeholder="black"
        controls
      />
    );
  }

  renderPopout: Function;
  /**
   * Return the element for popout.
   */
  /* eslint-disable no-alert */
  renderPopout() {
    return (
      this.props.streamName &&
      !this.state.error && (
        <PopoutVideo
          className={style.popout}
          onClick={() => {
            this.props.onPopOut();
            this.resetState();
          }}
        />
      )
    );
  }
  /* eslint-enable no-alert */

  /**
   * Return the element for error.
   */
  renderErrorMessage() {
    return (
      <NoVideo
        title={this.props.isReplay ? "RACE REPLAY" : "LIVE STREAMING"}
        description={
          this.props.isReplay
            ? this.props.replayErrorMessage
            : this.props.liveErrorMessage
        }
      />
    );
  }

  /**
   * Return the element that trigger the requests for play the video
   */
  renderVideoStarter() {
    if (
      !this.state.isLoading &&
      !this.state.error &&
      !this.state.link &&
      this.props.streamName
    ) {
      return (
        <button
          className={style.VideoPlayButton}
          onClick={() => {
            this.setLoading();
            this.handlePlayVideo();
          }}
        >
          <VideoIcon className={style.VideoIcon} />
        </button>
      );
    }

    return null;
  }

  render() {
    const videoClass = classnames(style.Video, this.props.videoClass, {
      [classnames(Loading.loading, Loading.loadingBig)]: this.state.isLoading
    });

    if (!this.props.userLoggedIn) {
      return (
        <div
          className={videoClass}
          ref={(ref) => {
            this.videoWrapper = ref;
          }}
        >
          <Login
            title={this.props.isReplay ? "RACE REPLAY" : "LIVE STREAMING"}
          />
        </div>
      );
    }

    return (
      <div
        className={videoClass}
        ref={(ref) => {
          this.videoWrapper = ref;
        }}
      >
        {(!this.props.streamName || this.state.error) &&
          this.renderErrorMessage()}
        {this.state.link && this.renderVideo()}
        {this.renderPopout()}
        {this.renderVideoStarter()}
      </div>
    );
  }
}
