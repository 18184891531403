// @flow
import _ from "lodash";
import { type Track } from "../../../types.flow";

declare type Action = {
  type: string,
  payload: Object | Error | boolean | null
};

const parseElement = (value: string) => {
  let result = _.attempt(JSON.parse, value);

  if (_.isError(result)) {
    result = [];
  }
  return result;
};

export function successRequest(lhnData: Object): Action {
  return {
    type: "LHN_REQUEST_SUCCESS",
    payload: lhnData
  };
}
export function successNoResults(): Action {
  // $FlowFixMe
  return {
    type: "LHN_REQUEST_SUCCESS_NO_RESULTS"
  };
}
export function failRequest(err: Error): Action {
  return {
    type: "LHN_REQUEST_FAIL",
    payload: err
  };
}

export function successCapiRequest(capiData: Object): Action {
  return {
    type: "LHN_CAPI_REQUEST_SUCCESS",
    payload: {
      featureToggles: _.has(capiData, "featureToggles")
        ? capiData.featureToggles
        : []
    }
  };
}

export function failCapiRequest(err: Error): Action {
  return {
    type: "LHN_CAPI_REQUEST_FAIL",
    payload: err
  };
}

export function openSideMenu(openMenuStatus: boolean): Action {
  return {
    type: "LHN_OPEN_SIDE_MENU",
    payload: openMenuStatus
  };
}

export function pendindRequest(): Action {
  return {
    type: "LHN_REQUEST_PENDING",
    payload: null
  };
}

export function changeProfile(user: Object): Action {
  return {
    type: "CHANGE_PROFILE",
    payload: user
  };
}

export function updateFavoriteTracks(tracks: String): Action {
  return {
    type: "UPDATE_FAVORITES_TRACKS",
    payload: tracks
  };
}

export function updateFilters(filterString: String): Action {
  let result = {};
  _.attempt(() => {
    // $FlowFixMe
    result = JSON.parse(filterString);
  });
  return {
    type: "UPDATE_FILTERS",
    payload: result
  };
}

/* this only request greyhounds for now */
export function successCmsRequest(cmsData: Object): Action {
  return {
    type: "CMS_REQUEST_SUCCESS",
    payload: {
      profiles: _.has(cmsData, "greyhoundsProfiles")
        ? // $FlowFixMe
          parseElement(cmsData.greyhoundsProfiles).profilesList
        : []
    }
  };
}

export function failCmsRequest(err: Error): Action {
  return {
    type: "CMS_REQUEST_FAIL",
    payload: err
  };
}

type DropSideProperties = {
  open: boolean,
  top: number,
  upsideDown: boolean,
  videoPlayerId: number
};
export function toggleDropSide(props: DropSideProperties): Action {
  return {
    type: "TOGGLE_DROPSIDE",
    payload: props
  };
}

type videoPlayerTrackData = {
  selectedTrack: Track
};

export function toggleSelectedTrackVideo(data: videoPlayerTrackData): Action {
  return {
    type: "TOGGLE_SELECTED_TRACK_VIDEO",
    payload: data
  };
}
