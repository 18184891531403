import _ from "lodash";

const updateRacesMTP = (races, racesMTP, limitLHNRacesTo120m = false) => {
  const now = new Date();
  return _.filter(races, (race) => {
    if (race.mtp < 60) {
      return _.find(
        racesMTP,
        (raceMtp) =>
          raceMtp.trackName === race.trackName &&
          raceMtp.number === race.raceNumber
      );
    }
    return true;
  })
    .filter((race) => {
      if (!limitLHNRacesTo120m) {
        return true;
      }
      const postTimeDate = new Date(race.postTime);
      const timePassed = postTimeDate - now;
      const minutesPassed = timePassed / 1000 / 60;
      return minutesPassed > -60;
    })
    .map((race) => {
      const tempRace = _.find(
        racesMTP,
        (raceMtp) =>
          raceMtp.trackName === race.trackName &&
          raceMtp.number === race.raceNumber
      );
      if (tempRace && tempRace.status.code !== "SK") {
        return {
          ...race,
          mtp: tempRace.mtp,
          raceStatus: tempRace.status
        };
      }
      return race;
    });
};

export default updateRacesMTP;
