// @flow
import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import * as mediator from "@tvg/mediator-classic/src";
import type { Element } from "react";
// $FlowFixMe
import VideoWrapper from "@tvg/ui-bootstrap/components/TVGVideoWrapper";
// Graph
// import VideoRaceInfo from '../../models/VideoRaceInfo';

/* const VIDEO_PLAYERS_COUNT: number = 2;
const TVG1 = new VideoRaceInfo(
  'TVG1',
  'TVG1',
  'Day',
  null,
  '',
  'tvg_mbr',
  {},
  false,
  '1',
  true,
  true
);

const TVG2 = new VideoRaceInfo(
  'TVG2',
  'TVG2',
  'Day',
  null,
  '',
  'tvg2_mbr',
  {},
  false,
  '2',
  true,
  true
);
*/

type Props = {
  isLogged: boolean,
  dispatch: Function
};

const callPopOut = (selectedTrack) =>
  _.throttle(
    () =>
      mediator.dispatch("TVG4:POPOUT_VIDEO", {
        selectedTrack: {
          trackAbbr: selectedTrack.trackAbbr,
          perfAbbr: selectedTrack.perfAbbr,
          streamCode: selectedTrack.streamCode,
          trackName: selectedTrack.trackName
        }
      }),
    3000,
    { leading: true, trailing: false }
  );

// $FlowFixMe
export class VideoTabList extends Component {
  constructor(props: Props): void {
    super(props);
    _.bindAll(this, [
      "loginLogout",
      "renderVideoList",
      "toggleDropSideCallback"
    ]);
    mediator.subscribe("TVG_LOGIN:USER_SESSION_UPDATE", this.loginLogout);
    this.state = {
      isLogged: this.props.isLogged
    };
  }

  // $FlowFixMe
  state: {
    isLogged: boolean
  };

  componentDidMount(): void {
    _.bindAll(this, ["renderVideoList"]);
  }

  loginLogout(data: Object) {
    // $FlowFixMe
    this.setState({
      isLogged: data && data.logged
    });
  }

  toggleDropSideCallback(
    videoPlayerId: number,
    topValue: number,
    upsideDownFlag: boolean
  ) {
    mediator.dispatch("LHN_SCROLL_DISABLE", { disableToggleValue: true });
    this.props.dispatch({
      type: "TOGGLE_DROPSIDE",
      payload: {
        open: true,
        videoPlayerId,
        top: topValue,
        upsideDown: upsideDownFlag
      }
    });
  }

  props: Props;

  // TODO Add video wrapper bet and login call backs
  renderVideoList(): Array<React$Element<any>> {
    const videoPlayers =
      _.get(this.props, "videoWrapperData.tvgChannels") || [];
    // TODO for now only return channels
    return videoPlayers.map((race) => (
      <li key={`lhnVideoList${race.trackAbbr}`}>
        <VideoWrapper
          id={race.trackAbbr}
          hasTracks
          selectedTrack={race}
          isFlash={race.isFlash}
          isLogged={this.props.isLogged}
          toggleDropDownCallback={this.toggleDropSideCallback}
          loginCallback={() =>
            mediator.dispatch("TVG_LOGIN:OPEN_LOGIN_MODAL", {})
          }
          onPopOut={callPopOut(race)}
        />
      </li>
    ));
  }

  render(): Element<*> {
    return <ul className="videoTabList">{this.renderVideoList()}</ul>;
  }
}

export default connect((store) => ({
  profile: store.lhnProfile.profile,
  isLogged: store.lhnProfile.isLogged,
  featureToggles: store.lhnFeatureToggles.featureToggles
}))(VideoTabList);
