// @flow

import React from "react";

/* eslint-disable react/no-unused-prop-types */

type Props = {
  closeDropdownCallBack: Function,
  children?: Array<Object>,
  customClass?: string
};

/* eslint-enable react/no-unused-prop-types */

const List = (props: Props) => {
  // all childrens of List gets as a prop function to close dropdown, recieved as a prop of List component
  const sendCloseCallBack = (children) =>
    React.Children.map(children, (child) =>
      React.cloneElement(child, {
        closeDropdownCallBack: props.closeDropdownCallBack
      })
    );

  return (
    <ul className={props.customClass}>{sendCloseCallBack(props.children)}</ul>
  );
};

List.defaultProps = {
  customClass: "",
  children: []
};

export default List;
