// @flow
import _ from "lodash";
import React, { Component } from "react";
import classNames from "classnames";
// $FlowFixMe
import { Scrollbars } from "react-custom-scrollbars";
import * as mediator from "@tvg/mediator-classic/src";
// $FlowFixMe
import RaceFilter from "@tvg/race-filter/src/pages/RaceFilter";
// $FlowFixMe
import ListIcon from "@tvg/ui-bootstrap/assets/svg/list.svg";
// $FlowFixMe
import LiveVideo from "@tvg/ui-bootstrap/assets/svg/live-video.svg";
import style from "./style.css";

type Props = {
  headerColumns: Array<string>,
  tabs: Array<Object>,
  withFilters: Boolean,
  showLhnHeader: boolean
};

// $FlowFixMe
class TabsSystem extends Component {
  constructor() {
    super();
    _.bindAll(this, [
      "tabsHeader",
      "tab",
      "tabsContent",
      "toggleScroll",
      "selectTab"
    ]);
  }

  state = {
    selectTab: 0,
    scrollDisabled: false
  };

  componentDidMount() {
    mediator.subscribe("LHN_SCROLL_DISABLE", this.toggleScroll);
  }

  componentWillUnmount() {
    mediator.unsubscribe("LHN_SCROLL_DISABLE", this.toggleScroll);
  }

  selectTab(index: number) {
    return () => {
      // Gtm Event
      mediator.dispatch("LHN_TAB_SELECT", {
        type: _.get(this.props, `headerColumns[${index}]`)
      });
      // $FlowFixMe
      return this.setState({ selectTab: index });
    };
  }

  tabsHeader(): React$Element<any> {
    const { headerColumns, tabs } = this.props;

    const headerLabels = headerColumns.map((label, index) => {
      const icon =
        label.toLowerCase().replace(" ", "") !== "livevideo" ? (
          <ListIcon width="16" height="16" />
        ) : (
          <LiveVideo width="16" height="16" />
        );
      const activeTab: ?string =
        // $FlowFixMe
        this.state.selectTab === index ? style.activeTab : "";
      return (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={`tabHeader${index}`}
          className={classNames(style.tab, activeTab)}
        >
          <button
            type="button"
            className={style.selectTab}
            disabled={_.get(tabs, `[${index}].props.disableTab`)}
            onClick={this.selectTab(index)}
          >
            <div className={style.buttonFlexWrapper}>
              <span className={style.tabIcon}>{icon}</span>
              <span className={style.tabLabel}>{label}</span>
            </div>
          </button>
        </li>
      );
    });

    return (
      <ul id="tabsWrapperHeader" className={style.tabsWrapper}>
        {headerLabels}
      </ul>
    );
  }

  tab(content: Object, index: number): React$Element<any> {
    // $FlowFixMe
    const showTab: string = index === this.state.selectTab ? style.tabShow : "";
    return (
      <li
        key={`tabContent${index}`}
        className={classNames(style.tabContent, showTab)}
      >
        {content}
      </li>
    );
  }

  tabsContent(): React$Element<any> {
    const { tabs } = this.props;
    return (
      <Scrollbars
        className={classNames(
          style.scrollBar,
          // $FlowFixMe
          this.state.scrollDisabled ? style.scrollBarDisabled : ""
        )}
        autoHide
        autoHideTimeout={1000}
        universal
      >
        <ul className={style.tabsContentWrapper}>{tabs.map(this.tab)}</ul>
      </Scrollbars>
    );
  }

  toggleScroll(data: Object): void {
    // $FlowFixMe
    this.setState({ scrollDisabled: data.disableToggleValue || false });
  }

  props: Props;

  render(): React$Element<any> {
    return (
      <div className={style.tabSystem}>
        {this.props.showLhnHeader && this.tabsHeader()}
        {this.props.withFilters &&
          // $FlowFixMe
          this.state.selectTab === 0 && <RaceFilter context="lhn" />}
        {this.tabsContent()}
      </div>
    );
  }
}
export default TabsSystem;
