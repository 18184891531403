// @flow

import React, { PureComponent } from "react";
import type { Element } from "react";
import classnames from "classnames";
import styles from "./index.css";

type ButtonBehavior = "button" | "submit" | "reset";
type ButtonTypes =
  | "primary"
  | "secondary"
  | "tertiary"
  | "secondaryAlt"
  | "yellow"
  | "legacy";

type ButtonSizes = "big" | "full-width" | "normal";

type Props = {
  /**
   * button | submit | reset
   */
  behavior: ButtonBehavior,
  /**
   * primary | secondary | tertiary | secondaryAlt | yellow
   */
  type: ButtonTypes,
  /**
   * normal | big | full-width
   */
  size: ButtonSizes,
  /**
   * Function To be Called On Click
   */
  onClick: (v: string) => mixed,
  /**
   * Button Value
   */
  value: string,
  /**
   * Is there an Icon
   */
  icon: boolean,
  /**
   * Icon position
   */
  iconPosition: "left" | "right",
  /**
   * Button Content
   */
  content: string,
  /**
   * Button Content
   */
  children: ?(Element<*>[]),
  /**
   * Additional Classes
   */
  className: string,

  /**
   * Is Selected
   */
  isSelected: boolean,

  /**
   * Is Disabled
   */
  disabled: boolean,

  /**
   * prefix to be added to data-qa-label
   */
  qaLabel: string
};

/**
 * TVG Button Component
 */
class Button extends PureComponent {
  static defaultProps = {
    behavior: "button",
    type: "primary",
    value: "",
    size: "normal",
    iconPosition: "left",
    className: "",
    children: [],
    isSelected: false,
    content: "",
    icon: false,
    disabled: false,
    qaLabel: ""
  };

  static getButtonProps(type: ButtonTypes | ButtonSizes) {
    const dict = {
      primary: styles.tvgBtnPrimary,
      secondary: styles.tvgBtnSecondary,
      tertiary: styles.tvgBtnTertiary,
      secondaryAlt: styles.tvgBtnSecondaryAlt,
      yellow: styles.tvgBtnMarketing,
      legacy: styles.tvgBtnLegacy,
      big: styles.big,
      "full-width": styles.fullWidth,
      normal: ""
    };

    return dict[type] || "";
  }

  props: Props;

  render() {
    const classes = classnames({
      [Button.getButtonProps(this.props.type)]: true,
      [Button.getButtonProps(this.props.size)]: true,
      [this.props.className]: true,
      [styles.selected]: this.props.isSelected,
      [styles.icon]: this.props.icon,
      [styles.iconRight]: this.props.iconPosition === "right"
    });

    return (
      <button
        type={this.props.behavior}
        className={classes}
        onClick={() => this.props.onClick(this.props.value)}
        value={this.props.value}
        disabled={this.props.disabled}
        data-qa-label={this.props.qaLabel.concat("Button")}
      >
        {this.props.content || this.props.children}
      </button>
    );
  }
}

export default Button;
