import VideoRaceInfo from "../models/VideoRaceInfo";

const getTVGChannel = (channel) =>
  new VideoRaceInfo(
    `TVG${channel}`,
    `TVG${channel}`,
    "Day",
    null,
    "",
    `tvg${channel > 1 ? channel : ""}_mbr`,
    {},
    false,
    `${channel}`,
    true,
    true
  );

export default getTVGChannel;
