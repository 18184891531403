// @flow
import React, { Component } from "react";
import * as mediator from "@tvg/mediator-classic/src";
import enhanceWithClickOutside from "react-click-outside";
import _ from "lodash";
import classnames from "classnames";
import Loading from "@tvg/ui-bootstrap/components/Loading";
import { getContextStyle } from "./factories/styleContext";
import styleDesktop from "./style.desktop.css";
import styleMobile from "./style.mobile.css";

import VoidStorage from "./components/Storage";
import type StorageInterface from "./components/Types.js.flow";
import setFilterPrefs from "./actions/setFilters";

import RaceFilterButtonTrigger from "./components/RaceFilterButtonTrigger";
import RaceFilterHeader from "./components/RaceFilterHeader";
import RaceFilterBreed from "./components/RaceFilterBreed";
import RaceFilterFavorites from "./components/RaceFilterFavorites";
import RaceFilterFooter from "./components/RaceFilterFooter";
import FavoriteTooltip from "./components/FavoriteTooltip";
import { optionsAlias } from "./models/filterBreedOptions";

const getStorage = (): StorageInterface => {
  if (typeof window === "object" && !!window.localStorage) {
    return window.localStorage;
  }
  return new VoidStorage();
};

const getOptionsAlias = (options) =>
  options.reduce((alias, option) => {
    alias.push(_.get(optionsAlias, option) || option);

    return alias;
  }, []);

const buildFiltersString = (tab, options) =>
  `${(tab !== "ALL" ? [tab] : []).concat(getOptionsAlias(options)).join(", ")}`;

const saveFilters = (filters) => {
  const raceFilters = JSON.stringify(filters);
  const data = mediator.query("TVG_LOGIN:USER_SESSION_UPDATE");
  const userId = _.get(data, "user.accountNumber");
  if (userId) {
    setFilterPrefs(userId, "raceFilters", raceFilters);
    mediator.dispatch("LOAD_FILTERS:UPDATE", { raceFilters });
    mediator.dispatch("RACE_FILTERS:UPDATE", {});
  }
};

const buildFullFilterName = (breeds) =>
  breeds.map((breed) => {
    switch (breed) {
      case "G":
        return "Greyhounds";
      case "T":
        return "Thoroughbred";
      case "Q":
        return "Quarter Horse";
      case "H":
        return "Harness";
      default:
        return false;
    }
  });

type Props = {
  context: string
};

class RaceFilter extends Component {
  constructor(props: Props) {
    super(props);
    this.storage = getStorage();
    this.buildFiltersString = buildFiltersString;

    const isLHNFirstVisit =
      this.props.context === "lhn"
        ? this.storage.getItem("lhnFiltersFirstVisit") || "true"
        : true;
    const isTouchFirstVisit =
      this.props.context === "mhdr"
        ? this.storage.getItem("touchFiltersFirstVisit") || "true"
        : true;
    const isFirstVisit = isLHNFirstVisit || isTouchFirstVisit || true;

    this.state = {
      group: "ALL",
      breed: [],
      isFilterOpened: false,
      isFirstVisit,
      isFilterLoading: false,
      isShowingFavoritesTooltip: false,
      askToShowTooltip: false
    };

    this.style = getContextStyle(this.props.context, styleDesktop, styleMobile);

    this.preChecked = this.state.breed;

    _.bindAll(this, [
      "getUserFilterStorage",
      "saveFiltersHandler",
      "updateGroup",
      "updateBreed",
      "handleClickOutside",
      "openCloseHandler",
      "clearFilters",
      "externalClearFilters",
      "renderFilterContainer",
      "loadFilterPreferences",
      "gtmOpenMenu",
      "gtmCloseMenu",
      "gtmClearFilters",
      "renderDesktop",
      "renderMobile",
      "renderFilterLoading",
      "closeTooltip",
      "openTooltip",
      "handleFinnishLoading"
    ]);
  }

  state: {
    group: string,
    isFirstVisit: boolean,
    breed: Array<string>,
    isFilterOpened: boolean,
    savedFilters: String,
    savedFilterString: String,
    isShowingFavoritesTooltip: boolean,
    askToShowTooltip: boolean
  };

  componentDidMount() {
    mediator.subscribe("RACE_FILTERS:CLEAR", this.externalClearFilters);
    mediator.subscribe("TRIGGER_FILTERS_MODAL", this.openCloseHandler);
    mediator.subscribe("FILTERS_LOADING_FINISHED", this.handleFinnishLoading);
    mediator.subscribeWithPast(
      "LOAD_FILTERS:UPDATE",
      this.loadFilterPreferences
    );
  }

  componentWillUnmount(): void {
    mediator.unsubscribe("RACE_FILTERS:CLEAR", this.externalClearFilters);
    mediator.unsubscribe("TRIGGER_FILTERS_MODAL", this.openCloseHandler);
    mediator.unsubscribe("FILTERS_LOADING_FINISHED", this.handleFinnishLoading);
    mediator.unsubscribe("LOAD_FILTERS:UPDATE", this.loadFilterPreferences);
  }

  getUserFilterStorage(raceFilters: string) {
    const filters = _.attempt(JSON.parse.bind(null, raceFilters));

    if (!!filters && !!filters.breed && !!filters.group) {
      this.setState({
        breed: filters.breed,
        group: filters.group,
        savedFilters: raceFilters,
        savedFilterString: this.buildFiltersString(filters.group, filters.breed)
      });
      this.preChecked = filters.breed;
    } else {
      this.setState({
        breed: [],
        group: "ALL",
        savedFilters: "",
        savedFilterString: ""
      });
    }
  }

  loadFilterPreferences(data: Object) {
    this.getUserFilterStorage(_.get(data, "raceFilters"));
  }

  props: Props;

  externalClearFilters() {
    this.setState({
      isFilterLoading: true
    });
    this.clearFilters();
    saveFilters({
      group: "ALL",
      breed: []
    });
  }

  clearFilters() {
    this.gtmClearFilters();

    this.setState({
      group: "ALL",
      breed: []
    });
  }

  saveFiltersHandler() {
    saveFilters({
      group: this.state.group,
      breed: this.state.breed
    });

    this.setState({
      savedFilterString: this.buildFiltersString(
        this.state.group,
        this.state.breed
      ),
      isFilterOpened: false,
      askToShowTooltip: false,
      isShowingFavoritesTooltip: false,
      isFilterLoading: true
    });

    mediator.dispatch("RACE_FILTER_SAVE_FILTERS", {
      preChecked: buildFullFilterName(this.preChecked),
      checked: buildFullFilterName(this.state.breed),
      racesReturned: "",
      racesInViewport: ""
    });
  }

  storage: StorageInterface;

  updateGroup(groupValue: string) {
    const group = groupValue === this.state.group ? "ALL" : groupValue;
    this.setState({
      group
    });
  }

  updateBreed(breedValue: string) {
    if (this.state.breed.indexOf(breedValue) === -1) {
      const breed = this.state.breed.slice();
      breed.push(breedValue);
      this.setState({
        breed
      });
    } else {
      this.setState({
        breed: this.state.breed.filter((el) => el !== breedValue)
      });
    }
  }

  gtmOpenMenu() {
    mediator.dispatch("RACE_FILTER_OPEN_MENU", {
      preChecked: buildFullFilterName(this.preChecked),
      context: this.props.context
    });
  }

  gtmCloseMenu() {
    mediator.dispatch("RACE_FILTER_CLOSE_MENU", {
      checked: buildFullFilterName(this.state.breed),
      preChecked: buildFullFilterName(this.preChecked),
      context: this.props.context
    });
    this.preChecked = [];
  }

  gtmClearFilters() {
    mediator.dispatch("RACE_FILTER_CLEAR_FILTERS", {
      checked: buildFullFilterName(this.state.breed),
      preChecked: buildFullFilterName(this.preChecked),
      context: this.props.context
    });
  }

  handleClickOutside() {
    if (this.state.isFilterOpened) {
      this.gtmCloseMenu();
      this.setState({
        isFilterOpened: false
      });
    }
  }

  closeTooltip() {
    this.setState({
      askToShowTooltip: false,
      isShowingFavoritesTooltip: false,
      isFilterLoading: false
    });
  }

  openTooltip() {
    this.setState({
      askToShowTooltip: true,
      isShowingFavoritesTooltip: true
    });
  }

  handleFinnishLoading(data) {
    // hack because setstate is assync
    setTimeout(() => {
      if (_.get(data, "showToolTip") === true && this.state.askToShowTooltip) {
        this.setState({
          askToShowTooltip: false,
          isFilterLoading: false
        });
        mediator.dispatch("SCROLL_TOP", {});
        setTimeout(() => {
          this.closeTooltip();
        }, 3000);
      } else {
        this.setState({
          isFilterLoading: false
        });
      }
    }, 0);
  }

  openCloseHandler() {
    if (!this.state.isFilterOpened) {
      this.gtmOpenMenu();

      const filters = _.attempt(JSON.parse.bind(null, this.state.savedFilters));

      this.setState({
        breed: _.get(filters, "breed") || [],
        group: _.get(filters, "group") || "ALL",
        isFilterOpened: !this.state.isFilterOpened,
        isFirstVisit: "false"
      });

      const lhnFirstVisitKey =
        this.props.context === "lhn" ? "lhnFiltersFirstVisit" : null;
      const touchFirstVisitKey =
        this.props.context === "mhdr" ? "touchFiltersFirstVisit" : null;
      const firstVisitStorageKey =
        lhnFirstVisitKey || touchFirstVisitKey || null;
      this.storage.setItem(firstVisitStorageKey, false);
    }
  }

  renderFilterContainer() {
    const containerClasses = classnames({
      [this.style.RaceFiltersContainer]: true,
      [this.style.RaceFiltersContainerIn]: this.state.isFilterOpened
    });

    return (
      <section className={containerClasses} data-qa-label="filterModal">
        {this.state.isFilterOpened && [
          <RaceFilterHeader
            context={this.props.context}
            closeComp={this.handleClickOutside}
          />,
          <main className={this.style.RaceFiltersCenterContainer}>
            <RaceFilterBreed
              context={this.props.context}
              values={this.state.breed}
              updateBreed={this.updateBreed}
            />
            <RaceFilterFavorites
              context={this.props.context}
              values={this.state.group}
              updateGroup={this.updateGroup}
              openTooltip={this.openTooltip}
              closeComp={this.handleClickOutside}
            />
          </main>,
          <RaceFilterFooter
            context={this.props.context}
            saveHandler={this.saveFiltersHandler}
            closeComp={this.handleClickOutside}
            clearFilters={this.clearFilters}
          />
        ]}
      </section>
    );
  }

  renderFilterLoading() {
    return (
      <div className={this.style.loading}>
        <Loading size={"big"} />
      </div>
    );
  }

  renderDesktop() {
    return (
      <div>
        <section
          className={this.style.WrapperContainer}
          data-qa-label="filterContainer"
        >
          <RaceFilterButtonTrigger
            context={this.props.context}
            savedFilters={this.state.savedFilterString}
            isFirstVisit={this.state.isFirstVisit}
            openCloseHandler={this.openCloseHandler}
            isFilterOpened={this.state.isFilterOpened}
          />
          {this.renderFilterContainer()}
        </section>
        {this.state.isFilterLoading && this.renderFilterLoading()}
      </div>
    );
  }

  renderMobile() {
    return (
      <div>
        {this.renderFilterContainer()}
        {this.state.isFilterLoading && this.renderFilterLoading()}
        {this.state.isShowingFavoritesTooltip &&
          !this.state.askToShowTooltip && (
            <FavoriteTooltip
              close={this.closeTooltip}
              message="Select your favorite tracks using the star"
              title="Add to Favorites"
            />
          )}
      </div>
    );
  }

  render() {
    let Filters;

    if (this.props.context === "lhn") {
      Filters = this.renderDesktop();
    }

    if (this.props.context === "mhdr") {
      Filters = this.renderMobile();
    }

    return Filters;
  }
}

RaceFilter.defaultProps = {
  context: "lhn"
};

export default enhanceWithClickOutside(RaceFilter);
