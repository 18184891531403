import "isomorphic-fetch";
import React from "react";
import { Provider } from "react-redux";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import configureStore from "./configureStore";
import App from "./pages";

const preloadedState = window.__LHN_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle
delete window.__LHN_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

const { store, client } = configureStore(false, preloadedState);

const hotRender = (Component) =>
  hydrate(
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Provider store={store}>
          <Component />
        </Provider>
      </BrowserRouter>
    </ApolloProvider>,
    document.getElementById("lhn")
  );

if (module.hot) {
  // $FlowBug
  module.hot.accept("./pages", () => {
    // $FlowBug
    System.import("./pages").then((AppModule) => hotRender(AppModule.default));
    System.import("./configureStore").then(() => hotRender(App));
  });
}

export default hotRender(App);
