/* import _ from 'lodash';
import moment from 'moment';
import VideoRaceInfo from '../../models/VideoRaceInfo';
*/

/* const processTimeString = (mtp, postTime) => {
  let returnVal = '';
  if (mtp && mtp <= 120) {
    returnVal = `${mtp}m`;
  } else if (postTime) {
    returnVal = moment(postTime).format('HH:mm A');
  }

  return returnVal;
}; */

const processGraphTrackList = (trackList = [], channels = []) => {
  // eslint-disable-line no-unused-vars
  const tvgChannels = channels;
  /* let filteredtracks = trackList.map(track => {
    const hasLiveStreaming = _.has(track, 'currentRace.video.liveStreaming')
      ? track.currentRace.video.liveStreaming
      : false;
    const hasStreamCode =
      _.has(track, 'currentRace.video.streams') &&
      !!track.currentRace.video.streams.length;
    const hasNextRaceNumber = _.has(track, 'currentRace.number');
    const hasStatusCode = _.has(track, 'currentRace.status.code');

    const timeLabel = hasNextRaceNumber
      ? processTimeString(track.currentRace.mtp, track.currentRace.postTime)
      : '';
    const filterOut =
      !hasLiveStreaming ||
      !hasStreamCode ||
      !hasNextRaceNumber ||
      !hasStatusCode;
    return !filterOut
      ? new VideoRaceInfo(
          track.name,
          track.code,
          track.perfAbbr,
          track.currentRace.number,
          timeLabel,
          track.currentRace.video.streams[0],
          track.currentRace.status.code,
          track.featured
        )
      : null;
  }) || [];

  filteredtracks = filteredtracks.filter(track => track !== null);

  const featureTracks = filteredtracks.filter(
    track => track.featured === true
  ) || [];
  const otherTracks = filteredtracks.filter(track => !track.featured) || [];

  const favoriteTracks = [];
  */
  // todo add video list after component is ready
  return {
    tvgChannels,
    featureTracks: [],
    otherTracks: [],
    favoriteTracks: []
  };
};

const videoListInfoServiceInstance = {
  processGraphTrackList
};
export default videoListInfoServiceInstance;
