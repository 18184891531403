import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { StyleSheet, Animated, Platform, Easing } from "react-native";
import { usePopper, useCloseOnClickOutside } from "../../hooks";
import {
  StyledView,
  StyledPressable,
  StyledContent
} from "./styled-components";
import { OverlayProps } from "./types";

/**
 * The overlay component will render the Popper content
 * it will have extra features for web such as clicking outside and closing
 * TODO: keyboard events
 *
 */
const Overlay = ({
  children,
  triggerRef,
  contentRef,
  on = "hover",
  zIndex = 1999
}: OverlayProps) => {
  const { isWeb, isOpen, closeContent } = usePopper();

  useCloseOnClickOutside({
    isWeb,
    refs: [contentRef, triggerRef],
    onPress: closeContent
  });

  const fadeAnim = useRef(new Animated.Value(0)).current;
  const scaleAnim = useRef(new Animated.Value(0.95)).current;
  const [showOpacity, setShowOpacity] = useState(false);

  useEffect(() => {
    if (isOpen) setShowOpacity(isOpen);

    Animated.parallel([
      Animated.timing(fadeAnim, {
        toValue: isOpen ? 1 : 0,
        duration: 200,
        useNativeDriver: Platform.OS !== "web",
        easing: Easing.inOut(Easing.ease)
      }),
      Animated.timing(scaleAnim, {
        toValue: isOpen ? 1 : 0.95,
        duration: 200,
        useNativeDriver: Platform.OS !== "web",
        easing: Easing.inOut(Easing.ease)
      })
    ]).start(() => {
      setShowOpacity(isOpen);
    });
  }, [isOpen]);

  const styles = StyleSheet.create({
    toolTip: {
      position: "absolute",
      cursor: "default",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  });

  return showOpacity
    ? ReactDOM.createPortal(
        on === "press" ? (
          <StyledView
            zIndex={zIndex}
            pointerEvents="none"
            style={[
              styles.toolTip,
              {
                opacity: fadeAnim,
                transform: [{ scale: scaleAnim }]
              }
            ]}
          >
            <StyledPressable onPress={closeContent} zIndex={zIndex} />
            <StyledContent zIndex={zIndex}>{children}</StyledContent>
          </StyledView>
        ) : (
          <StyledView
            zIndex={zIndex}
            pointerEvents="none"
            style={[
              styles.toolTip,
              {
                opacity: fadeAnim,
                transform: [{ scale: scaleAnim }]
              }
            ]}
          >
            {children}
          </StyledView>
        ),
        document.body
      )
    : null;
};

export default Overlay;
