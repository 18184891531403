const lhnQuery = `query getLhnInfo(
$wagerProfile: String,
$trackFilter: TrackListFilter,
$product: String,
$device: String,
$brand: String,
$raceFilter: RaceListFilter,
$withGreyhounds: Boolean
){
    lhnTracks: tracks (profile: $wagerProfile, filter: $trackFilter){
        id
        races (filter: $raceFilter, page: {current: 0, results: 2}, sort:{byPostTime: ASC}){
            id
            mtp
            number
            postTime
            isGreyhound
            location {
              country
            }
            track {
                code
                name
                perfAbbr
            }
            highlighted (product: $product, device: $device, brand: $brand) {
                description
                pinnedOrder
                action
                style
            }
            type {
                code
            }
            status {
                code
            }
            video {
                onTvg
                onTvg2
            }
        }
    }
    lhnFeatureRaces: races (filter: {isHighlighted: true, status: ["MO", "O", "SK", "IC"],  allRaceClasses: $withGreyhounds}, profile: $wagerProfile){
          id
          mtp
          number
          postTime
          isGreyhound
          location {
            country
          }
          track {
              code
              name
              perfAbbr
          }
          highlighted (product: $product, device: $device, brand: $brand) {
              description
              pinnedOrder
              action
              style
          }
          type {
              code
          }
          status {
              code
          }
          video {
              onTvg
              onTvg2
          }
    }
}
`;

export default lhnQuery;
