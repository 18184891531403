// @flow
export default class Poller {
  pollerInstance: number | null;

  constructor(): void {
    this.pollerInstance = null;
  }

  refresh(callBack: Function, interval: number): void {
    // Make initial request
    callBack();
    // set poller
    // $FlowFixMe
    this.pollerInstance = setInterval(callBack, interval);
  }

  stop() {
    // $FlowFixMe
    return this.pollerInstance && clearInterval(this.pollerInstance);
  }

  start(callBack: Function, interval: number) {
    if (this.pollerInstance) {
      // $FlowFixMe
      clearInterval(this.pollerInstance);
    }
    // $FlowFixMe
    this.refresh(callBack, interval);
  }
}
