export default {
  success: true,
  promoPlacements: [
    {
      placementId: "RACING",
      promotions: [
        {
          promoCode: "RDEPOSITTEST",
          name: "Racing Deposit Test",
          title: "Racing Deposit Test",
          description: "Racing Deposit Test goat",
          termsAndConditions: {
            full: "terms",
            url: "https://casino.fanduel.nxt.fndl.dev/promotions/RDEPOSITTEST"
          },
          images: [
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/27/2023-09-27_13-33-05_1280x926.png",
              width: 1280,
              height: 926,
              tag: "banner"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/27/2023-09-27_13-33-05_640x463.png",
              width: 640,
              height: 463,
              tag: "banner"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/27/2023-09-27_13-33-20_1280x926.png",
              width: 1280,
              height: 926,
              tag: "square"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/27/2023-09-27_13-33-20_640x463.png",
              width: 640,
              height: 463,
              tag: "square"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/27/2023-09-27_13-33-20_320x232.png",
              width: 320,
              height: 232,
              tag: "square"
            }
          ],
          callToAction: {
            url: "https://account.mi.casino.fanduel.nxt.fndl.dev/login?external-referrer-next=promotions%3FpromoCode%3DRDEPOSITTEST",
            text: "optin",
            action: "OPT_IN"
          },
          theme: "RACING",
          brand: "FANDUEL",
          products: ["SPORTSBOOK", "CASINO", "RACING"],
          currency: "$",
          currencies: ["USD"],
          status: "ACTIVE",
          registrationCode: "",
          endDate: "",
          fulfillmentEndDate: "",
          showTimeLeft: true,
          customerPromotionState: {
            steps: [
              {
                action: "Opt in to the promotion.",
                completed: false,
                status: "WAITING"
              },
              {
                action: "Deposit a minimum of $7.00",
                completed: false,
                status: "WAITING"
              }
            ],
            eligibility: {
              canOptIn: true,
              canConsent: true,
              eligible: true
            },
            criteriaState: {
              fulfilled: false,
              ratio: 0.0,
              category: "GAUGE",
              params: {
                gauge: {
                  current: 0.0,
                  target: 7.0,
                  minValue: 7.0,
                  type: "DEPOSIT_AMOUNT"
                }
              }
            },
            reward: {
              id: "BonusAwardingRewardModel",
              type: "BONUS_AWARDING",
              value: 7.0
            },
            promoStateExpiryDate: "",
            fulfillmentEndDate: "",
            optInState: "NOT_OPTED_IN",
            hasAccepted: false
          }
        },
        {
          promoCode: "RACINGCROUSL",
          name: "RACING TEST CROUSL TAG",
          title: "RACING TEST CROUSL TAG",
          description: "description<br>",
          termsAndConditions: {
            full: "terms",
            url: "https://casino.fanduel.nxt.fndl.dev/promotions/RACINGCROUSL"
          },
          images: [
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/29/2023-09-29_08-46-24_275x183.jpg",
              width: 275,
              height: 183,
              tag: "banner"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/29/2023-09-29_08-46-24_137x91.jpg",
              width: 137,
              height: 91,
              tag: "banner"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/29/2023-09-29_08-47-12_275x183.jpg",
              width: 275,
              height: 183,
              tag: "square"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/29/2023-09-29_08-47-12_137x91.jpg",
              width: 137,
              height: 91,
              tag: "square"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/29/2023-09-29_08-47-12_68x45.jpg",
              width: 68,
              height: 45,
              tag: "square"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/29/2023-09-29_08-48-00_275x183.jpg",
              width: 275,
              height: 183,
              tag: "carousel"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/29/2023-09-29_08-48-00_137x91.jpg",
              width: 137,
              height: 91,
              tag: "carousel"
            }
          ],
          callToAction: {
            url: "https://account.mi.casino.fanduel.nxt.fndl.dev/login?external-referrer-next=promotions%3FpromoCode%3DRACINGCROUSL",
            text: "opt in",
            action: "REDIRECT"
          },
          theme: "SPORTSBOOK",
          brand: "FANDUEL",
          products: ["SPORTSBOOK", "RACING"],
          currency: "$",
          currencies: ["USD"],
          status: "ACTIVE",
          registrationCode: "",
          endDate: "",
          fulfillmentEndDate: "",
          showTimeLeft: true,
          customerPromotionState: {
            steps: [
              {
                action: "Opt in to the promotion.",
                completed: false,
                status: "WAITING"
              },
              {
                action: "Deposit a minimum of $25.00",
                completed: false,
                status: "WAITING"
              }
            ],
            eligibility: {
              canOptIn: false,
              canConsent: false,
              eligible: true
            },
            criteriaState: {
              fulfilled: false,
              ratio: 0.0,
              category: "GAUGE",
              params: {
                gauge: {
                  current: 0.0,
                  target: 25.0,
                  minValue: 25.0,
                  type: "DEPOSIT_AMOUNT"
                }
              }
            },
            reward: {
              id: "BonusAwardingRewardModel",
              type: "BONUS_AWARDING",
              variableAmount: {
                maxAmount: 100.0,
                ratio: 0.5
              }
            },
            promoStateExpiryDate: "",
            fulfillmentEndDate: "",
            optInState: "NOT_OPTED_IN",
            hasAccepted: false
          }
        },
        {
          promoCode: "SBKCROSSRAC",
          name: "Cross Cum Força",
          title: "Cross Cum Força",
          description: "Bota dinheiro em SBK e get blim blim em Racing",
          termsAndConditions: {
            full: "Terms&Conds",
            url: "https://casino.fanduel.nxt.fndl.dev/promotions/SBKCROSSRAC"
          },
          images: [
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/27/2023-09-27_13-56-37_1202x932.png",
              width: 1202,
              height: 932,
              tag: "banner"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/27/2023-09-27_13-56-37_601x466.png",
              width: 601,
              height: 466,
              tag: "banner"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/27/2023-09-27_13-56-38_1202x932.png",
              width: 1202,
              height: 932,
              tag: "square"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/27/2023-09-27_13-56-38_601x466.png",
              width: 601,
              height: 466,
              tag: "square"
            },
            {
              url: "https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/2023/9/27/2023-09-27_13-56-38_300x233.png",
              width: 300,
              height: 233,
              tag: "square"
            }
          ],
          callToAction: {
            url: "https://account.mi.casino.fanduel.nxt.fndl.dev/login?external-referrer-next=promotions%3FpromoCode%3DSBKCROSSRAC",
            text: "I Dare You",
            action: "REDIRECT"
          },
          theme: "RACING",
          brand: "FANDUEL",
          products: ["SPORTSBOOK", "CASINO", "RACING"],
          currency: "$",
          currencies: ["USD"],
          status: "ACTIVE",
          registrationCode: "",
          endDate: "",
          fulfillmentEndDate: "",
          showTimeLeft: true,
          customerPromotionState: {
            steps: [
              {
                action: "Opt in to the promotion.",
                completed: false,
                status: "WAITING"
              },
              {
                action: "Deposit a minimum of $13.00",
                completed: false,
                status: "WAITING"
              }
            ],
            eligibility: {
              canOptIn: false,
              canConsent: false,
              eligible: true
            },
            criteriaState: {
              fulfilled: false,
              ratio: 0.0,
              category: "GAUGE",
              params: {
                gauge: {
                  current: 0.0,
                  target: 13.0,
                  minValue: 13.0,
                  type: "DEPOSIT_AMOUNT"
                }
              }
            },
            reward: {
              id: "BonusAwardingRewardModel",
              type: "BONUS_AWARDING",
              value: 17.0
            },
            promoStateExpiryDate: "",
            fulfillmentEndDate: "",
            optInState: "NOT_OPTED_IN",
            hasAccepted: false
          }
        }
      ],
      totalPromotions: 4
    }
  ]
};
