// @flow
import _ from "lodash";
import React, { Component } from "react";
import classNames from "classnames";
import * as mediator from "@tvg/mediator-classic/src";
import { TvBrand } from "@tvg/design-system";
// $FlowFixMe
import Mtp from "@tvg/ui-bootstrap/components/Mtp/";

import style from "./style.css";

declare type RaceStatus = {
  code: string
};

declare type RaceInfo = {
  trackName: string,
  country: string,
  perfAbbr: string,
  raceNumber: string,
  importanceLevel: string,
  description: string,
  isGreyhound: boolean,
  mtp: number,
  postTime: string,
  raceStatus: RaceStatus,
  onTvg: boolean,
  onTvg2: boolean,
  trackAbbr: string,
  typeCode: string
};

type Props = {
  raceInfo: RaceInfo,
  showOnTVG: boolean
};

const goToRace = ({
  trackName,
  trackAbbr,
  raceNumber,
  isGreyhound,
  importanceLevel,
  raceStatus,
  // $FlowFixMe
  mtp
}: {
  trackName: string,
  trackAbbr: string,
  raceNumber: string,
  isGreyhound: boolean,
  importanceLevel: string,
  // $FlowFixMe
  raceStatus: object
}) => {
  const track = encodeURIComponent(
    trackName.trim().replace(/\s/g, "-").replace(/-+/g, "-").toLowerCase()
  );
  const link = isGreyhound ? "greyhounds" : "racetracks";
  const autoPlay = _.get(raceStatus, "code") === "SK" ? "/autoplay/true" : "";

  mediator.dispatch("CLOSE_HAMBURGUER", {});

  // Gtm Event
  mediator.dispatch("LHN_RACE_NAVIGATION", {
    race: `${trackName} - ${mtp}`,
    type: _.isUndefined(importanceLevel) ? "Standard" : "Featured",
    url: `${_.get(
      window,
      "location.hostname"
    )}/${link}/${trackAbbr}/${track}?race=${raceNumber}`
  });

  mediator.dispatch("TVG4_NAVIGATION", {
    route: `/${link}/${trackAbbr}/${track}${autoPlay}?race=${raceNumber}`
  });
};

export default class LHN extends Component<Props, *> {
  getDescription(extraClass: any) {
    return this.props.raceInfo.description ? (
      <div className={classNames(style.raceStructLine)}>
        <span className={classNames(style.description, extraClass || "")}>
          {this.props.raceInfo.description}
        </span>
      </div>
    ) : (
      ""
    );
  }

  props: Props;

  render() {
    const { raceInfo } = this.props;
    const isFeaturedLiveRace =
      _.has(raceInfo, "importanceLevel") &&
      this.props.showOnTVG &&
      (raceInfo.onTvg || raceInfo.onTvg2);
    const leftBarRaceStyle = classNames({
      [style.raceWrapper]: true,
      [style.featuredLiveRace]: isFeaturedLiveRace
    });

    return (
      <li
        className={leftBarRaceStyle}
        data-qa-label={isFeaturedLiveRace ? "tvgLiveRaces" : ""}
        data-qa-race-breed={raceInfo.typeCode}
      >
        <button
          type="button"
          className={style.goToRace}
          onClick={() => goToRace(raceInfo)}
        >
          <div
            className={classNames(
              style.lhnRace,
              _.has(raceInfo, "importanceLevel") ? style.highlighted : ""
            )}
            data-qa-label="highlightedRace"
          >
            <div className={classNames(style.leftSection)}>
              <Mtp
                containerClass="mtpContainer"
                iconClass="mtpIconClass"
                mtp={raceInfo.mtp}
                postTime={raceInfo.postTime}
                statusCode={raceInfo.raceStatus.code}
                timeClass={style.timeClass}
                liveClass={style.live}
                minutesClass={style.timeClass}
                inline
              />
            </div>
            <div className={classNames(style.rightSection)}>
              {this.props.showOnTVG && (raceInfo.onTvg || raceInfo.onTvg2) && (
                <div className={style.onTvg}>
                  <span>ON</span>
                  <TvBrand color="white" height={12} />
                </div>
              )}
              {raceInfo.importanceLevel === "1" && this.getDescription()}
              <div
                className={classNames(
                  style.raceStructLine,
                  style.trackRaceName
                )}
              >
                <span className={classNames(style.trackName)}>
                  {raceInfo.trackName}
                </span>
                <span className={classNames(style.raceNumber)}>
                  {`R${raceInfo.raceNumber}`}
                </span>
              </div>
              {raceInfo.importanceLevel !== "1" &&
                !raceInfo.isGreyhound &&
                this.getDescription(style.lowerImportanceDescription)}
              {raceInfo.isGreyhound && (
                <span className={classNames(style.greyhoundLabel)}>
                  GREYHOUND
                </span>
              )}
            </div>
          </div>
        </button>
      </li>
    );
  }
}
