// @flow

import React, { Component, PropTypes } from "react"; //eslint-disable-line
import classnames from "classnames";
import style from "./style.css";
import Tick from "../../../assets/svg/tick.svg";

type Props = {
  /**
   * On Change Function that will be triggered when switch changes state
   */
  onChange: (v: boolean) => void,

  /**
   * Id for label to work proprely this needs to always be always diffrent
   * @type {[string]}
   */
  id: string,

  /**
   * Children
   * @type {Element}
   */
  children: Element,

  /**
   * prefix to be added to data-qa-label
   */
  qaLabel: string,

  /**
   * Value to set/unset status of checkbox
   * @type {boolean}
   */
  checked: boolean,

  /**
   * Changes the layout to mobile
   * @type {boolean}
   */
  mobile: boolean
};

/**
 * TVG Checkbox Component
 */
class Checkbox extends Component {
  static defaultProps = {
    qaLabel: ""
  };

  constructor(props: Props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange: (c: boolean) => void;

  onChange(e: Object) {
    if (this.props.onChange) {
      this.props.onChange(e.target.checked);
    }
  }

  props: Props;

  render() {
    const checkboxClass = classnames({
      [style.checkbox]: true,
      [style.checkbox__mobile]: this.props.mobile
    });

    return (
      <div className={checkboxClass}>
        <input
          type="checkbox"
          id={this.props.id}
          checked={this.props.checked}
          onChange={(e) => this.onChange(e)}
          data-qa-label={this.props.qaLabel.concat("Field")}
        />
        <label
          htmlFor={this.props.id}
          data-qa-label={this.props.qaLabel.concat("Label")}
        >
          <Tick width="16px" height="16px" fill="#5c9de0" />
          {this.props.children}
        </label>
      </div>
    );
  }
}

export default Checkbox;
