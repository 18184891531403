import DOMPurify from "dompurify";
import { openExternalLink } from "@tvg/sh-utils/mobileUtils";

export const calculateProbableValue = (
  comboAmount: number,
  minWagerAmount: number,
  userAmount: number
): number => (comboAmount * userAmount) / minWagerAmount;

export const pluralize = (
  count: number,
  word: string,
  showCount: boolean = true,
  suffix: string = "s"
) => {
  const hasMoreThanOne = count > 1;
  const wordPluralized = hasMoreThanOne ? `${word}${suffix}` : word;
  return showCount ? `${count} ${wordPluralized}` : wordPluralized;
};

export const parseWithTarget = (desc: string) => {
  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    if ("target" in node) {
      node.setAttribute("id", node.getAttribute("href") || "");
      setTimeout(() => {
        const element = document.getElementById(
          node.getAttribute("href") || ""
        );
        if (element)
          element.addEventListener("click", (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            openExternalLink(element.getAttribute("href") || "");
          });
      }, 50);
    }
  });

  return DOMPurify.sanitize(desc);
};

export default pluralize;
