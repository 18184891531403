export default function reducer(
  state = {
    profile: "PORT-Generic",
    isLogged: false,
    favoritesTracks: [],
    greyhoundsProfiles: [],
    filters: {}
  },
  action
) {
  switch (action.type) {
    case "CHANGE_PROFILE": {
      return {
        ...state,
        profile: action.payload.profile || "PORT-Generic",
        isLogged: action.payload.isLogged
      };
    }
    case "UPDATE_FAVORITES_TRACKS": {
      return {
        ...state,
        favoritesTracks: action.payload
      };
    }
    case "UPDATE_FILTERS": {
      return {
        ...state,
        filters: action.payload
      };
    }
    case "CMS_REQUEST_SUCCESS": {
      return { ...state, greyhoundsProfiles: action.payload.profiles };
    }
    case "CMS_REQUEST_FAIL": {
      return { ...state, greyhoundsProfiles: [] };
    }
    default: {
      return state;
    }
  }
}
