// @flow
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import type { Store } from "redux";
import reducers from "./pages/lhn/reducers";
import ServiceUrl from "./pages/lhn/factories/serviceUrl"; // eslint-disable-

let composeEnhancers = compose;
// $FlowFixMe
if (typeof DEVELOPMENT !== "undefined") {
  /* eslint-disable */
  composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */
}

export default (
  serverSide: boolean = false,
  initialState: *
): { store: Store<*, *>, client: Client } => {
  const link = createHttpLink({
    uri: ServiceUrl.getServiceUrl("service.graph"),
    credentials: "include"
  });

  const cache = new InMemoryCache({});

  const client = new ApolloClient({
    link,
    cache,
    queryDeduplication: true,
    ssrMode: serverSide
  });

  const middlewares = [thunk];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(combineReducers(reducers), initialState, enhancer);

  // $FlowFixMe
  if (module.hot) {
    // $FlowBug
    module.hot.accept("./pages/lhn/reducers", () =>
      // $FlowBug
      System.import("./pages/lhn/reducers").then((reducerModule) =>
        store.replaceReducer(
          combineReducers({
            ...reducerModule.default,
            apollo: client.reducer()
          })
        )
      )
    );
  }

  return { store, client };
};
