// @flow
import _ from "lodash";
import { type FeatureToggle } from "../../../types.flow";

const extractFeatureToggleValue = (
  featureToggles: Array<FeatureToggle>,
  toggleName: string
): boolean => {
  const toggleObject: Array<FeatureToggle> = featureToggles.length
    ? featureToggles.filter((obj) => obj.name === toggleName)
    : [{ name: "", enabled: false, percentage: 0 }];

  return toggleObject.length && _.has(toggleObject[0], "enabled")
    ? toggleObject[0].enabled
    : false;
};

export default extractFeatureToggleValue;
