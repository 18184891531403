// @flow

import classNames from "classnames";
import React from "react";
import style from "./style.css";

type Props = {
  name: string,
  number: number,
  timeLabel: string,
  customClass?: string,
  closeDropdownCallBack?: Function,
  shouldCloseOnItemClick?: boolean,
  onClickFn?: Function,
  harness: boolean
};

const Item = (props: Props) => {
  const onClick = () => {
    if (typeof props.onClickFn === "function") {
      props.onClickFn();
    }
    // props.shouldCloseOnItemClick - to close or not dropdown. By default is true - to close.
    if (
      props.shouldCloseOnItemClick &&
      typeof props.closeDropdownCallBack === "function"
    ) {
      props.closeDropdownCallBack();
    }
    return this;
  };

  const customClass = props.customClass
    ? { [props.customClass]: props.customClass }
    : {};

  const liStyle = classNames({
    [style.trackSelectorListLi]: true,
    ...customClass
  });

  return (
    <li className={liStyle}>
      <a onClick={onClick} role="button" tabIndex={0}>
        <div className={style.trackSelectorListName}> {props.name} </div>
        <div className={style.trackSelectorListLiHarness}>
          {" "}
          {props.harness && "(H)"}
        </div>
        <div className={style.trackSelectorListLiRace}>
          <strong> {props.number ? `R${props.number}` : ""} </strong>
        </div>
        <div className={style.trackSelectorListMTP}> {props.timeLabel} </div>
      </a>
    </li>
  );
};

Item.defaultProps = {
  shouldCloseOnItemClick: true,
  onClickFn: null,
  customClass: "",
  closeDropdownCallBack: null
};

export default Item;
