// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import LeftBarRace from "../LeftBarRace";
import updateRacesMTP from "../../factories/raceMtpUpdate";
import style from "./style.css";
import transitions from "./transitions.css";
import NoRaces from "../NoRaces";

type Props = {
  racesMTP: Array<Object>,
  leftBarRaces: Array<Object>,
  leftBarRacesLoading: boolean,
  filtersApplied: boolean,
  featureToggles: Array<Object>
};

// $FlowFixMe
export class LHNRaces extends Component {
  props: Props;

  genRaceList() {
    let limitLHNRacesTo120m = _.find(this.props.featureToggles, [
      "name",
      "limitLHNRacesTo120m"
    ]);
    limitLHNRacesTo120m = _.get(limitLHNRacesTo120m, "enabled", false);
    const { leftBarRacesLoading, leftBarRaces, racesMTP } = this.props;
    let returnValue = "";
    if (leftBarRaces.length) {
      returnValue = (
        <TransitionGroup
          className={style.raceList}
          exit
          enter={false}
          appear={false}
          component="ul"
        >
          {_.uniqBy(
            updateRacesMTP(leftBarRaces, racesMTP, limitLHNRacesTo120m),
            "trackAbbr"
          ).map((raceInfo) => (
            <CSSTransition
              classNames={{
                exit: transitions.leave,
                exitActive: transitions.leaveActive
              }}
              timeout={{ exit: 600 }}
            >
              <LeftBarRace
                key={`leftBarRace${raceInfo.trackAbbr}-${raceInfo.raceNumber}`}
                raceInfo={raceInfo}
                showOnTVG
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      );
    } else if (!leftBarRaces.length && !leftBarRacesLoading) {
      returnValue = [<NoRaces filtersApplied={this.props.filtersApplied} />];
    }

    return returnValue;
  }

  render() {
    const { leftBarRacesLoading } = this.props;

    return (
      <div className="nextRacesWrapper">
        <div
          className={classNames(
            style.loadingLayer,
            !leftBarRacesLoading ? style.hiddenLoading : ""
          )}
        />
        {this.genRaceList()}
      </div>
    );
  }
}
export default connect((store) => ({
  leftBarRaces: store.lhnRaces.leftBarRaces,
  leftBarRacesLoading: store.lhnRaces.leftBarRacesLoading,
  featureToggles: store.lhnFeatureToggles.featureToggles
}))(LHNRaces);
